const useInputDelay = (
  cb: (v: string) => Promise<void>,
  ms: number
): ((args: string) => Promise<void>) => {
  let timer;

  return async args => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(cb, ms, args);
  };
};

export default useInputDelay;
