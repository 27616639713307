import {
  AreaInfo,
  AreaInfoIdEnum,
  ProjectBasicInfoCmBuyingKindEnum,
  ReachAnalysisProject,
  ReachAnalysisSummaries
} from '@/api/openapi';
import { getAvailableDate } from '@/common/dates';
import { DATE_FORMAT, DATE_FORMAT_SLASH } from '@/common/format';
import { INVALID_DATE_TOAST } from '@/common/messages';
import { validDuration } from '@/common/validation';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { toast } from '@/components/ui/Toast';
import { useAreaOptions } from '@/store/areaOptions';
import { useBrandLiftStore } from '@/store/brandLift';
import { differenceInHours, format, parse } from 'date-fns';
import { storeToRefs } from 'pinia';
import { Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

interface useSummaryReturnType {
  summary: Ref<ReachAnalysisSummaries | undefined>;
  project: Ref<ReachAnalysisProject | undefined>;
  fetch: (isDefault: boolean) => Promise<void>;
  numOfEffectiveContacts: Ref<string | undefined>;
  area: Ref<string | undefined>;
  isConfirmed: Ref<boolean>;
  today: string;
  date: Ref<DateRange | undefined>;
  fetching: Ref<boolean>;
  targetType: Ref<string>;
  target: Ref<string>;
  durationError: Ref<string>;
  isChangeCondition: Ref<boolean>;
  areaLabel: Ref<string | undefined>;
  checkConfirmed: (
    endDateString: string,
    area: AreaInfoIdEnum | undefined
  ) => boolean | undefined;
  cmBuyingKindLabel: Ref<string | undefined>;
}

export const useSummary = (
  reachAnalysisProjectId: string
): useSummaryReturnType => {
  const today = new Date();
  const isFirstChangeCondition = ref<boolean>(false);
  const isConfirmed = ref<boolean>(false);

  const durationError = ref<string>('');

  const store = useBrandLiftStore();
  const areaOptionStore = useAreaOptions();
  const areaLabel = ref();
  const cmBuyingKindLabel = ref();

  const route = useRoute();

  const {
    isFetchingSummary: fetching,
    date,
    area,
    numOfEffectiveContacts,
    project,
    summary,
    targetType,
    target,
    isChangeCondition
  } = storeToRefs(store);

  const checkConfirmed = (
    endDateString: string,
    area: AreaInfoIdEnum | undefined
  ) => {
    const isKeyArea =
      area === undefined ||
      area == AreaInfoIdEnum.Kanto ||
      area == AreaInfoIdEnum.Kansai ||
      area == AreaInfoIdEnum.Chukyo;
    if (endDateString) {
      const endDate = new Date(endDateString);
      endDate.setHours(5, 0, 0, 0);
      const diffInHours = differenceInHours(today, endDate);
      isConfirmed.value = diffInHours >= 72 || !isKeyArea;
      return isConfirmed.value;
    }
  };

  const validateDate = (start: Date, end: Date, areaInfo?: AreaInfo) => {
    const availableDate = getAvailableDate(areaInfo?.id);
    if (start < availableDate || end < availableDate) {
      INVALID_DATE_TOAST(availableDate, areaInfo);
    }
  };

  const buyingKindLbabel = (
    buyingKind: ProjectBasicInfoCmBuyingKindEnum
  ): string => {
    switch (buyingKind) {
      case ProjectBasicInfoCmBuyingKindEnum.All:
        return 'すべて';
      case ProjectBasicInfoCmBuyingKindEnum.Time:
        return 'タイムのみ';
      case ProjectBasicInfoCmBuyingKindEnum.Spot:
        return 'スポットのみ';
      default:
        return 'すべて';
    }
  };

  const fetch = async () => {
    fetching.value = true;
    isChangeCondition.value = false;
    await store.fetchProject(reachAnalysisProjectId);
    await areaOptionStore.fetch(route);

    numOfEffectiveContacts.value = project?.value?.targetSettings?.numOfEffectiveContacts?.toString();
    area.value = project.value?.targetSettings.area as
      | AreaInfoIdEnum
      | undefined;
    const _area = areaOptionStore.option(area.value);
    areaLabel.value = _area?.name;
    cmBuyingKindLabel.value = buyingKindLbabel(
      project?.value?.basicInfo.cmBuyingKind ??
        ProjectBasicInfoCmBuyingKindEnum.All
    );
    const startDate = project?.value?.basicInfo?.campaignStartDate;
    const endDate = project?.value?.basicInfo?.campaignEndDate;
    if (startDate && endDate) {
      const start = parse(startDate, DATE_FORMAT, today);
      const end = parse(endDate, DATE_FORMAT, today);
      date.value = {
        start,
        end
      };
      checkConfirmed(endDate, _area?.id);
    }
    fetching.value = false;
  };

  watch([area, date], () => onChangeCondition());
  watch([numOfEffectiveContacts], () => onChangeEffectiveContacts());
  watch(isChangeCondition, newValue => {
    if (newValue) {
      toast({
        title:
          '期間・エリアを変更したため、出稿金額に関連する指標が非表示になりました。再度表示する場合はページを読み込み直してください',
        variant: 'success'
      });
    }
  });

  const onChangeEffectiveContacts = async () => {
    await fitch();
  };

  const onChangeCondition = async () => {
    if (date.value) {
      if (validDuration(date.value)) {
        if (isFirstChangeCondition.value && !isChangeCondition.value) {
          isChangeCondition.value = true;
        }
        isFirstChangeCondition.value = true;
        if (date.value.start && date.value.end) {
          validateDate(
            date.value.start,
            date.value.end,
            areaOptionStore.option(area.value)
          );
        }
        await fitch();
      } else {
        durationError.value = '7日間以上を選択してください';
      }
    } else {
      // 起こり得ないエラー
      durationError.value = '期間を指定してください';
    }
  };
  const fitch = async () => {
    const start = date?.value?.start || '';
    const end = date?.value?.end || '';
    durationError.value = '';
    fetching.value = true;
    await store.fetchSummary(
      numOfEffectiveContacts.value
        ? Number(numOfEffectiveContacts.value)
        : undefined,
      area.value,
      start ? format(start, DATE_FORMAT) : undefined,
      end ? format(end, DATE_FORMAT) : undefined
    );
    checkConfirmed(format(new Date(end), DATE_FORMAT), area.value);
    fetching.value = false;
  };

  return {
    numOfEffectiveContacts,
    area,
    isConfirmed,
    summary: summary,
    project: project,
    fetch,
    today: format(today, DATE_FORMAT_SLASH),
    date,
    fetching,
    targetType,
    target,
    durationError,
    isChangeCondition,
    areaLabel,
    checkConfirmed,
    cmBuyingKindLabel
  };
};
