
import { defineComponent, computed, ref, PropType, watch, toRefs } from 'vue';
import { storeToRefs } from 'pinia';
import { TARGET_NAME, BASIC_TARGET_DIVISION } from '@/common/constant';
import { useBasicTargetsStore } from '@/store/basicTargets';
import { useRoute } from 'vue-router';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import CheckboxButtonGroup from '@/components/ui/CheckboxButtonGroup.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import Icon from '@/components/ui/Icon.vue';
import Select from '@/components/ui/Select.vue';
import Tooltip from '@/components/ui/Tooltip.vue';
import Typography from '@/components/ui/Typography.vue';
import { ReachAnalysisTargetSettingTargetTypeEnum } from '@/api/openapi';
import DataSearchMultiSelect from '@/pages/datasearch/DataSearchMultiSelect.vue';

type valueOf<T> = T[keyof T];

type CustomTargetType = {
  id: number;
  label: string;
  isEnabled?: boolean | undefined;
};

export const convertToTarget = (
  targetType?: ReachAnalysisTargetSettingTargetTypeEnum
): valueOf<typeof TARGET_NAME> => {
  switch (targetType) {
    case ReachAnalysisTargetSettingTargetTypeEnum.Individual:
      return TARGET_NAME.individual;
    case ReachAnalysisTargetSettingTargetTypeEnum.IndividualAndHousehold:
      return TARGET_NAME.individualAndHousehold;
    case ReachAnalysisTargetSettingTargetTypeEnum.Household:
      return TARGET_NAME.household;
    case ReachAnalysisTargetSettingTargetTypeEnum.BasicGa12:
      return TARGET_NAME.genderAge12Type;
    case ReachAnalysisTargetSettingTargetTypeEnum.BasicGa10S:
      return TARGET_NAME.gender10Type;
    case ReachAnalysisTargetSettingTargetTypeEnum.Custom:
      return TARGET_NAME.customTarget;
    default:
      return TARGET_NAME.individual;
  }
};

export type CustomTargetValue = {
  id?: number;
  label?: string;
  isEnabled?: boolean;
};

export default defineComponent({
  name: 'SelectTarget',
  components: {
    DataSearchMultiSelect,
    Box,
    CheckboxButtonGroup,
    Checkbox,
    Flex,
    Icon,
    Tooltip,
    Select,
    Typography
  },
  props: {
    target: String, // typeof TARGET_NAME
    customTargetList: Array as PropType<
      { id?: number; label: string; isEnabled?: boolean }[]
    >,
    size: {
      type: String,
      default: 's'
    },
    disabled: Boolean,
    isFree: Boolean,
    hideOptions: {
      type: Array,
      default: () => []
    },
    initialTargetValue: [String, Array, Object] as PropType<
      number[] | string | undefined
    >,
    addHousehold: Boolean, // MEMO: 世帯を単独で追加
    withHousehold: Boolean, // MEMO: 世帯を個人全体・世帯で追加
    multiple: {
      type: Boolean,
      default: false
    },
    bulk: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const basicTargetStore = useBasicTargetsStore();
    const { basicTargets } = storeToRefs(basicTargetStore);
    const targetName = ref(props.target || '');
    const genderAge12TypeValue = ref<number[]>([]);
    const gender10TypeValue = ref<number[]>([]);
    const customTargetValue = ref();
    const targetValue = ref<{
      name: string;
      value?: string | number[] | { [x: string]: string | number };
    }>({
      name: targetName.value,
      value: ''
    });
    const customTargetListData = ref(props.customTargetList);
    const route = useRoute();
    const companyId = parseInt(route.params.companyId.toString());
    const initTargetType = ref();

    const options = computed(() => {
      let _options: string[] = [
        props.withHousehold
          ? TARGET_NAME.individualAndHousehold
          : TARGET_NAME.individual,
        props.addHousehold ? TARGET_NAME.household : '',
        TARGET_NAME.genderAge12Type,
        TARGET_NAME.gender10Type
      ].filter(value => value !== '' && !props.hideOptions?.includes(value));
      if (props.customTargetList) {
        _options = [..._options, TARGET_NAME.customTarget];
      }
      return _options;
    });

    const onChangeTarget = (targetName: string) => {
      targetValue.value.name = targetName;
      if (targetName === TARGET_NAME.genderAge12Type) {
        targetValue.value.value = genderAge12TypeValue.value;
      } else if (targetName === TARGET_NAME.gender10Type) {
        targetValue.value.value = gender10TypeValue.value;
      } else if (targetName === TARGET_NAME.customTarget) {
        targetValue.value.value = customTargetValue.value;
      } else if (targetName === TARGET_NAME.individualAndHousehold) {
        targetValue.value.value = TARGET_NAME.individualAndHousehold;
      } else if (targetName === TARGET_NAME.household) {
        targetValue.value.value = TARGET_NAME.household;
      } else {
        targetValue.value.value = TARGET_NAME.individual;
      }
      emit('change', targetValue.value);
    };

    const onChangeValue = (
      value: Array<number | CustomTargetType> | CustomTargetValue
    ) => {
      // なんで返り値がobjectだったりarrayだったり。。
      if (Array.isArray(value)) {
        const ids = value.map(v => (typeof v === 'number' ? v : v.id));
        const val =
          targetValue.value.name === TARGET_NAME.customTarget
            ? ids
            : ids.sort((a, b) => a - b);
        emit('change', {
          name: targetName.value,
          value: val
        });
      } else {
        emit('change', {
          name: targetName.value,
          value
        });
      }
    };

    const { initialTargetValue, target, customTargetList } = toRefs(props);
    watch(
      [initialTargetValue, customTargetList],
      values => {
        const [_initialTargetValue, _customTargetList] = values;
        if (_customTargetList) {
          customTargetListData.value = _customTargetList
            .map(target => ({
              ...target,
              icon: target.isEnabled === false ? 'alert' : undefined,
              $isDisabled: target.isEnabled === false ? true : false
            }))
            .sort((a, b) => (a.label > b.label ? 1 : -1));
          if (props.multiple) {
            customTargetListData.value = customTargetListData.value.filter(
              v => v.isEnabled
            );
          }
        }
        if (!_initialTargetValue || !target.value || !_customTargetList) return;
        targetName.value = target.value;
        if (target.value === TARGET_NAME.customTarget) {
          customTargetValue.value = _customTargetList?.filter(
            custom =>
              Array.isArray(_initialTargetValue) &&
              custom.id &&
              _initialTargetValue.includes(custom.id)
          );
        } else if (
          target.value === TARGET_NAME.gender10Type &&
          Array.isArray(_initialTargetValue)
        ) {
          gender10TypeValue.value = _initialTargetValue;
        } else if (
          target.value === TARGET_NAME.genderAge12Type &&
          Array.isArray(_initialTargetValue)
        ) {
          genderAge12TypeValue.value = _initialTargetValue;
        }
      },
      {
        immediate: true
      }
    );

    watch(
      initialTargetValue,
      () => {
        if (target.value) initTargetType.value = props.target;
      },
      {
        immediate: true
      }
    );

    const deleteCustomMsg = computed(() => {
      if (
        target.value === TARGET_NAME.customTarget &&
        (!customTargetValue.value || customTargetValue.value.length === 0) &&
        Array.isArray(initialTargetValue.value) &&
        initTargetType.value === TARGET_NAME.customTarget
      ) {
        // カスタムターゲットが削除された場合
        if (!initialTargetValue.value[0]) {
          return '設定中のターゲットが削除されたため、利用できません。別のターゲットを指定してください。';
        }
      }
      return '';
    });

    const invalidCustomMsg = computed(() => {
      if (
        target.value === TARGET_NAME.customTarget &&
        initTargetType.value === TARGET_NAME.customTarget
      ) {
        // カスタムターゲットで使用中のオプションが無効になった場合
        if (customTargetValue.value[0]?.isEnabled === false) {
          return '利用できない条件が含まれています。別のターゲットを指定するか、カスタムターゲットを修正してください。';
        }
      }
      return '';
    });

    const searchCustomTarget = (query: string) => {
      return new Promise(resolve => {
        if (query.trim().length === 0) {
          resolve(customTargetListData.value);
        }
        const result = customTargetListData.value?.filter(v =>
          v.label.includes(query)
        );
        resolve(result);
      });
    };

    /**
     * 全選択
     */
    const bulkSelect = ref(false); // 全選択
    const bulkVisible = ref(false); // 全選択
    const bulkItems = computed(() => {
      const list = basicTargets.value.filter(
        v => v.displayDivisionName === targetName.value
      );
      return list[0] ? list[0].targets.map(v => v.id) : [];
    });
    const onChangeItemsBulk = value => {
      bulkSelect.value = value.length === bulkItems.value.length; // 全選択
    };
    const onChangeSelectBulk = targetName => {
      bulkVisible.value = false;
      if (targetName === TARGET_NAME.genderAge12Type) {
        bulkVisible.value = true;
        // 初回読み込み時nullの場合がある
        if (bulkItems.value.length > 1) {
          bulkSelect.value =
            genderAge12TypeValue.value.length === bulkItems.value.length;
        }
      } else if (targetName === TARGET_NAME.gender10Type) {
        bulkVisible.value = true;
        // 初回読み込み時nullの場合がある
        if (bulkItems.value.length > 1) {
          bulkSelect.value =
            gender10TypeValue.value.length === bulkItems.value.length;
        }
      }
    };
    const bulkSelectClikck = () => {
      // 対応したv-modelに値を追加
      if (targetName.value === TARGET_NAME.gender10Type) {
        gender10TypeValue.value = bulkSelect.value ? bulkItems.value : [];
      }
      if (targetName.value === TARGET_NAME.genderAge12Type) {
        genderAge12TypeValue.value = bulkSelect.value ? bulkItems.value : [];
      }
      emit('change', {
        name: targetName.value,
        value: bulkSelect.value ? bulkItems.value : []
      });
    };

    return {
      options,
      TARGET_NAME,
      BASIC_TARGET_DIVISION,
      targetName,
      gender10TypeValue,
      genderAge12TypeValue,
      customTargetValue,
      customTargetListData,
      onChangeTarget,
      onChangeValue,
      basicTargets,
      companyId,
      deleteCustomMsg,
      invalidCustomMsg,
      searchCustomTarget,
      bulkSelect,
      bulkVisible,
      bulkSelectClikck,
      onChangeItemsBulk,
      onChangeSelectBulk
    };
  }
});
