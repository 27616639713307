
import { defineComponent, computed, PropType } from 'vue';
import { DATE_FORMAT } from '@/common/format';
import { DatePicker as BaseDatePicker } from 'v-calendar';
import { format } from 'date-fns';
import { MIN_DATE } from '@/common/constant';
import Icon from '@/components/ui/Icon.vue';
import IconButton from '@/components/ui/IconButton.vue';
import TextField from '@/components/ui/TextField.vue';
import Flex from '@/components/layout/Flex.vue';

export type DateRange = {
  start: Date | null;
  end: Date | null;
};

type SingleDateModelValue = Date | null;

type ModelValue = DateRange | SingleDateModelValue;

export default defineComponent({
  components: { BaseDatePicker, Flex, Icon, IconButton, TextField },
  props: {
    modelValue: Object as PropType<ModelValue>,
    isRange: Boolean,
    name: {
      type: String,
      default: 'datepicker'
    },
    closable: {
      type: Boolean,
      default: true
    },
    size: String,
    placeholder: String,
    dense: Boolean,
    errorMessage: String || false,
    maxDate: Date,
    minDate: {
      type: Date,
      default: undefined
    },
    isRequired: Boolean,
    error: {
      type: Boolean,
      default: false
    },
    disabledDates: {
      type: Object
    },
    isCustom: Boolean,
    customRange: Object as PropType<DateRange>,
    disabled: Boolean
  },
  setup(props, { emit }) {
    const dateValue = computed({
      get: () => props.modelValue,
      set: value => {
        return emit('update:modelValue', value || props.modelValue);
      }
    });

    const isDateValueSet = computed(() => {
      if (props.modelValue && 'start' in props.modelValue) {
        const { start, end } = props.modelValue;
        return !!start && !!end;
      } else {
        return !!props.modelValue;
      }
    });

    const textValue = computed(() => {
      if (props.isCustom && props.customRange) {
        const { start, end } = props.customRange;
        const startStr = start ? format(start, DATE_FORMAT) : '';
        const endStr = end ? '〜' + format(end, DATE_FORMAT) : '';
        return startStr + endStr;
      }
      if (props.modelValue && 'start' in props.modelValue) {
        const { start, end } = props.modelValue;
        const startStr = start ? format(start, DATE_FORMAT) : '';
        const endStr = end ? '〜' + format(end, DATE_FORMAT) : '';
        return startStr + endStr;
      } else {
        if (props.modelValue) {
          return format(props.modelValue, DATE_FORMAT);
        } else {
          return '';
        }
      }
    });

    const resetDate = () => {
      if (props.modelValue && 'start' in props.modelValue) {
        emit('update:modelValue', { start: null, end: null });
      } else {
        emit('update:modelValue', null);
      }
    };

    const min = computed(() => props.minDate ?? new Date(MIN_DATE));

    return {
      // 【NOTE】
      // MIN_DATE ... 2021/12/27以前のデータがないのでTVALの共通ルール
      // ここで定義していいのか要検討。
      min,
      dateValue,
      isDateValueSet,
      textValue,
      resetDate
    };
  }
});
