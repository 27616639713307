import { ProjectApi, ReachAnalysisApi } from '@/api';
import {
  AreaInfoIdEnum,
  ReachAnalysisProject,
  ReachAnalysisSummaries,
  ReachAnalysisTargetSettingTargetTypeEnum
} from '@/api/openapi';
import { TARGET_NAME } from '@/common/constant';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { toast } from '@/components/ui/Toast';
import { defineStore } from 'pinia';

export const useBrandLiftStore = defineStore('brandLiftStore', {
  state: () => ({
    project: undefined as ReachAnalysisProject | undefined,
    isFetchingSummary: false as boolean,
    summary: undefined as ReachAnalysisSummaries | undefined,
    area: undefined as AreaInfoIdEnum | undefined,
    date: undefined as DateRange | undefined,
    numOfEffectiveContacts: undefined as string | undefined,
    isChangeCondition: false as boolean
  }),
  actions: {
    async fetchProject(reachAnalysisProjectId: string) {
      const result = await ProjectApi.getReachAnalysisProject(
        Number(reachAnalysisProjectId)
      ).catch(e => {
        throw new Error(e.name);
      });
      if (result) {
        this.project = result.data;
      }
    },
    async fetchSummary(
      effectiveNumberOfViews?: number,
      area?: AreaInfoIdEnum,
      start?: string,
      end?: string
    ) {
      try {
        const basicInfo = this.project?.basicInfo;
        const targetSettings = this.project?.targetSettings;
        if (
          basicInfo?.reachAnalysisProjectId &&
          targetSettings?.area &&
          targetSettings?.numOfEffectiveContacts &&
          basicInfo?.campaignStartDate &&
          basicInfo?.campaignEndDate
        ) {
          const reachAnalysisId = basicInfo.reachAnalysisProjectId;
          const result = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary(
            reachAnalysisId,
            effectiveNumberOfViews ?? targetSettings.numOfEffectiveContacts,
            start ?? basicInfo.campaignStartDate,
            end ?? basicInfo.campaignEndDate,
            area ?? targetSettings.area
          );
          const status = result.status;
          if (200 <= status && status < 300) this.summary = await result.data;
          else
            toast({
              title: 'エラー',
              message: '指標一覧が取得できません',
              variant: 'error'
            });
        }
      } catch (e) {
        toast({
          title: 'エラー',
          message: '指標一覧が取得できません',
          variant: 'error'
        });
      }
    },
    resetState() {
      this.project = undefined;
      this.isFetchingSummary = true;
      this.summary = undefined;
      this.area = undefined;
      this.date = undefined;
      this.numOfEffectiveContacts = undefined;
    }
  },
  getters: {
    targetType: state => {
      const target = state.project?.targetSettings?.targetType;
      switch (target) {
        case ReachAnalysisTargetSettingTargetTypeEnum.Individual:
          return TARGET_NAME.individual;
        case ReachAnalysisTargetSettingTargetTypeEnum.BasicGa10S:
          return TARGET_NAME.gender10Type;
        case ReachAnalysisTargetSettingTargetTypeEnum.BasicGa12:
          return TARGET_NAME.genderAge12Type;
        case ReachAnalysisTargetSettingTargetTypeEnum.Custom:
          return TARGET_NAME.customTarget;
        default:
          return '';
      }
    },
    target: state => {
      switch (state.project?.targetSettings?.targetType) {
        case ReachAnalysisTargetSettingTargetTypeEnum.Individual:
          return TARGET_NAME.individual;
        default:
          return (
            state.project?.targetSettings?.targets
              .map(({ targetName }) => targetName)
              .join(', ') || ''
          );
      }
    },
    hasCompetitive: state => {
      return (
        state.project?.comparisonSettings?.productComparisonProducts &&
        state.project?.comparisonSettings?.productComparisonProducts.length > 0
      );
    },
    hasOwnCompany: state => {
      return (
        state.project?.comparisonSettings?.inhouseComparisonProducts &&
        state.project?.comparisonSettings?.inhouseComparisonProducts.length > 0
      );
    }
  }
});
