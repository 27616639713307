import { AreaInfoIdEnum } from '@/api/openapi';
import { format, isAfter, subDays } from 'date-fns';

// 2021/12/27
const AREA_KANTO_AVAILABLE_DATES = new Date(2021, 11, 27);
const AREA_KANSAI_AVAILABLE_DATES = new Date(2022, 3, 4);
const AREA_OTHER_AVAILABLE_DATES = new Date(2022, 4, 2);

export const getAvailableDate = (area: AreaInfoIdEnum | undefined): Date => {
  switch (area) {
    case AreaInfoIdEnum.Kanto:
      return AREA_KANTO_AVAILABLE_DATES;
    case AreaInfoIdEnum.Kansai:
      return AREA_KANSAI_AVAILABLE_DATES;
    default:
      return AREA_OTHER_AVAILABLE_DATES;
  }
};

export const getAvailableEndDate = (area: AreaInfoIdEnum | undefined): Date => {
  const today = new Date();
  const formatDate = format(today, 'HH:mm');
  switch (area) {
    case AreaInfoIdEnum.Kanto:
    case AreaInfoIdEnum.Kansai:
    case AreaInfoIdEnum.Chukyo:
      if (formatDate >= '15:00') {
        return subDays(today, 1);
      } else {
        return subDays(today, 2);
      }
    default:
      if (formatDate >= '05:00') {
        return subDays(today, 3);
      } else {
        return subDays(today, 4);
      }
  }
};
export const getAvailableEndDateForProgram = (
  area: AreaInfoIdEnum | undefined
): Date => {
  const today = new Date();
  const formatDate = format(today, 'HH:mm');

  switch (area) {
    case AreaInfoIdEnum.Kanto:
    case AreaInfoIdEnum.Kansai:
    case AreaInfoIdEnum.Chukyo:
      if (formatDate >= '15:00') {
        return subDays(today, 1);
      } else {
        return subDays(today, 2);
      }
    default:
      if (formatDate >= '05:00') {
        return subDays(today, 2);
      } else {
        return subDays(today, 3);
      }
  }
};

export const getAvailableEndDateForTime = (): Date => {
  const today = new Date();
  const formatDate = format(today, 'HH:mm');
  if (formatDate >= '09:00') {
    return subDays(today, 1);
  } else {
    return subDays(today, 2);
  }
};

export const isEnableDate = (
  date: Date,
  area: AreaInfoIdEnum | undefined
): boolean => {
  const ad = getAvailableDate(area);
  return isAfter(date, ad);
};

export const ENABLED_MESSAGE = '視聴データ未提供の期間が含まれています';
