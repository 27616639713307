import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Flex = _resolveComponent("Flex")!
  const _component_BaseDatePicker = _resolveComponent("BaseDatePicker")!

  return (_openBlock(), _createBlock(_component_BaseDatePicker, {
    modelValue: _ctx.dateValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateValue) = $event)),
    "disabled-dates": _ctx.disabledDates,
    "first-day-of-week": 2,
    "is-range": _ctx.isRange,
    "min-date": _ctx.min,
    "max-date": _ctx.maxDate,
    class: _normalizeClass([[_ctx.size, _ctx.dense ? 'dense' : ''], "ui-datepicker"]),
    "is-required": _ctx.isRequired,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(({ showPopover }) => [
      _createVNode(_component_Flex, {
        "align-center": "",
        class: "calendar-field-container",
        onClick: () => (!_ctx.disabled ? showPopover() : {})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TextField, {
            modelValue: _ctx.textValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textValue) = $event)),
            dense: _ctx.dense,
            name: _ctx.name,
            size: _ctx.size,
            readonly: true,
            placeholder: _ctx.placeholder,
            "error-message": _ctx.errorMessage,
            error: !!_ctx.errorMessage || _ctx.error,
            class: "calendar-field",
            disabled: _ctx.disabled
          }, null, 8, ["modelValue", "dense", "name", "size", "placeholder", "error-message", "error", "disabled"]),
          (_ctx.closable && !_ctx.disabled)
            ? _withDirectives((_openBlock(), _createBlock(_component_IconButton, {
                key: 0,
                "icon-name": "close-circle",
                class: "close-icon",
                onClick: _withModifiers(_ctx.resetDate, ["stop"])
              }, null, 8, ["onClick"])), [
                [_vShow, _ctx.isDateValueSet]
              ])
            : _createCommentVNode("", true),
          (!_ctx.disabled)
            ? (_openBlock(), _createBlock(_component_Icon, {
                key: 1,
                name: "calendar",
                class: "calendar-icon",
                color: "primary"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue", "disabled-dates", "is-range", "min-date", "max-date", "class", "is-required", "disabled"]))
}