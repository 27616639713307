import { TvDataSearchApi } from '@/api';
import {
  AreaInfoIdEnum,
  TvDataSearchCmAggregateHistoryTargetTypeEnum,
  TvDataSearchCmListHistoryTargetTypeEnum,
  TvDataSearchCmrfHistoryTargetTypeEnum,
  TvDataSearchCmrfHistoryViewingTypeEnum,
  TvDataSearchCmListHistoryViewingTypeEnum,
  TvDataSearchCmAggregateHistoryViewingTypeEnum
} from '@/api/openapi';
import { TARGET_NAME } from '@/common/constant';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { Ref, ref } from 'vue';

interface Filter {
  label: string;
  value: string;
}
interface Header {
  filter?: boolean;
  sortable?: boolean;
  id: string;
  label?: string;
  class: string;
  matchMode?: string;
  filterOptions?: Filter[];
  filterField?: string;
}

interface DataTabelFilter {
  id: string;
  operator: string;
  constraints: { value: null; matchMode: string }[];
}
interface DataTableReturnType {
  filters: Ref<{ [key: string]: DataTabelFilter }>;
  isDisabled: (data: DataTabelType) => boolean;
  getTooltipMessage: (data: DataTabelType) => string;
}

export interface DataTabelType {
  cmProductName: string;
  cmSponsorName: string;
  targets: string;
  startDate: Date;
  endDate: Date;
  area: string;
  allConditions: unknown;
  searchedAt: Date;
  id: number;
  isCustomTargetNotDeleted: boolean;
  isProductUnchanged: boolean;
}

const STR_FILTER: Filter[] = [
  { label: '等しい', value: FilterMatchMode.EQUALS },
  { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
  { label: '含む', value: FilterMatchMode.CONTAINS },
  { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
];
const DATE_FILTER: Filter[] = [
  { label: '等しい', value: FilterMatchMode.DATE_IS },
  { label: '含まない', value: FilterMatchMode.DATE_IS_NOT },
  { label: '以前', value: FilterMatchMode.DATE_BEFORE },
  { label: 'より後', value: FilterMatchMode.DATE_AFTER }
];
export const HEADERS: Header[] = [
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'cmProductName',
    label: '商品／ブランド',
    class: 'p-sortable-column--lg',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'cmSponsorName',
    label: '企業名',
    class: 'p-sortable-column--md',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'targets',
    label: 'ターゲット',
    class: 'p-sortable-column--lg',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'date',
    id: 'startDate',
    label: '開始日',
    class: 'p-sortable-column--sh center',
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'date',
    id: 'endDate',
    label: '終了日',
    class: 'p-sortable-column--sh center',
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'area',
    label: 'エリア',
    class: 'p-sortable-column--sh center',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    id: 'allConditions',
    label: '全ての条件',
    class: 'p-sortable-column--sm center'
  },
  {
    filter: true,
    sortable: true,
    filterField: 'date',
    id: 'searchedAt',
    label: '検索日時',
    class: 'p-sortable-column--smd center',
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER
  },
  {
    id: 'id',
    class: 'p-sortable-column--sh center'
  }
];

export const useDataTable = (): DataTableReturnType => {
  const filters = ref();
  const tmp = HEADERS.map(v => {
    if (!v.filter) return;
    return {
      id: v.id,
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: v.matchMode }]
    };
  }).filter(Boolean) as DataTabelFilter[];
  filters.value = tmp.reduce((p, c) => {
    return { ...p, [c.id]: c };
  }, {});

  const isDisabled = (data: DataTabelType): boolean => {
    return !data.isCustomTargetNotDeleted || !data.isProductUnchanged;
  };

  const getTooltipMessage = (data: DataTabelType): string => {
    if (!data.isCustomTargetNotDeleted)
      return 'カスタムターゲットが削除されたため利用できません';
    if (!data.isProductUnchanged)
      return '商品／ブランドが変更されたため利用できません';
    return '';
  };

  return {
    filters,
    isDisabled,
    getTooltipMessage
  };
};

interface ConvertReturnType {
  convertToIsConversion15sec: (type: boolean | undefined) => string;
  convertToViewingType: (
    type:
      | TvDataSearchCmrfHistoryViewingTypeEnum
      | TvDataSearchCmListHistoryViewingTypeEnum
      | TvDataSearchCmAggregateHistoryViewingTypeEnum
  ) => string;
  convertToCmType: (type: string | undefined) => string;
  convertToCmDuration: (type: number | undefined) => string;
  convertToStationName: (area: string, codes: string[]) => string[];
  convertToTargetName: (
    targetType:
      | TvDataSearchCmListHistoryTargetTypeEnum
      | TvDataSearchCmAggregateHistoryTargetTypeEnum
      | TvDataSearchCmrfHistoryTargetTypeEnum
  ) => string;
  convertToAreaName: (areaValue: string | undefined) => string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useHistoryModal = (companyId: number): ConvertReturnType => {
  const stationsByArea: { [x: string]: { name: string; code: string }[] } = {};
  Object.values(AreaInfoIdEnum).map(area => {
    stationsByArea[area] = [];
  });

  const init = async () => {
    Object.values(AreaInfoIdEnum).map(async area => {
      const res = await TvDataSearchApi.getTvdataSearchStations(
        area,
        companyId,
        false
      );
      const list: { name: string; code: string }[] = [];
      res.data.map(v => {
        list.push({
          name: v.displayStationName,
          code: v.stationCodes[0]
        });
      });
      stationsByArea[area] = list;
    });
  };

  const convertToStationName = (area: string, codes: string[]): string[] => {
    const stations = stationsByArea[area];
    const nameList: string[] = [];
    codes.map(code => {
      const match = stations?.find(s => s.code === code);
      if (match) nameList.push(match.name);
    });
    if (nameList.length > 0) {
      return [...nameList];
    }
    return [];
  };

  const convertToCmType = (type: string | undefined): string => {
    switch (type) {
      case 'TIME':
        return 'タイム';
      case 'SPOT':
        return 'スポット';
      default:
        return '全CM';
    }
  };

  const convertToViewingType = (
    type:
      | TvDataSearchCmrfHistoryViewingTypeEnum
      | TvDataSearchCmListHistoryViewingTypeEnum
      | TvDataSearchCmAggregateHistoryViewingTypeEnum
      | undefined
  ): string => {
    switch (type) {
      case TvDataSearchCmrfHistoryViewingTypeEnum.Realtime ||
        TvDataSearchCmListHistoryViewingTypeEnum.Realtime ||
        TvDataSearchCmAggregateHistoryViewingTypeEnum.Realtime:
        return 'リアルタイム';
      case TvDataSearchCmrfHistoryViewingTypeEnum.Total ||
        TvDataSearchCmListHistoryViewingTypeEnum.Total ||
        TvDataSearchCmAggregateHistoryViewingTypeEnum.Total:
        return 'トータル';
      default:
        return 'リアルタイム';
    }
  };

  const convertToIsConversion15sec = (type: boolean | undefined): string => {
    return type === null || type === undefined ? '-' : type ? 'する' : 'しない';
  };

  const convertToCmDuration = (type: number | undefined): string => {
    switch (type) {
      case 15:
        return type + '秒';
      case 30:
        return type + '秒以上';
      default:
        return '全CM';
    }
  };

  const convertToTargetName = (
    targetType:
      | TvDataSearchCmListHistoryTargetTypeEnum
      | TvDataSearchCmAggregateHistoryTargetTypeEnum
      | TvDataSearchCmrfHistoryTargetTypeEnum
  ): string => {
    switch (targetType) {
      case TvDataSearchCmListHistoryTargetTypeEnum.Custom ||
        TvDataSearchCmAggregateHistoryTargetTypeEnum.Custom ||
        TvDataSearchCmrfHistoryTargetTypeEnum.Custom:
        return TARGET_NAME.customTarget;
      case TvDataSearchCmListHistoryTargetTypeEnum.IndividualAndHousehold ||
        TvDataSearchCmAggregateHistoryTargetTypeEnum.IndividualAndHousehold ||
        TvDataSearchCmrfHistoryTargetTypeEnum.IndividualAndHousehold:
        return TARGET_NAME.individualAndHousehold;
      case TvDataSearchCmListHistoryTargetTypeEnum.BasicGa12 ||
        TvDataSearchCmAggregateHistoryTargetTypeEnum.BasicGa12 ||
        TvDataSearchCmrfHistoryTargetTypeEnum.BasicGa12:
        return TARGET_NAME.genderAge12Type;
      case TvDataSearchCmListHistoryTargetTypeEnum.BasicGa10S ||
        TvDataSearchCmAggregateHistoryTargetTypeEnum.BasicGa10S ||
        TvDataSearchCmrfHistoryTargetTypeEnum.BasicGa10S:
        return TARGET_NAME.gender10Type;
      default:
        return TARGET_NAME.individualAndHousehold;
    }
  };

  const convertToAreaName = (areaInfoId: string | undefined): string => {
    switch (areaInfoId) {
      case AreaInfoIdEnum.Kanto:
        return '関東';
      case AreaInfoIdEnum.Kansai:
        return '関西';
      case AreaInfoIdEnum.Chukyo:
        return '中京';
      case AreaInfoIdEnum.Hokkaido:
        return '北海道';
      case AreaInfoIdEnum.Tohoku:
        return '東北';
      case AreaInfoIdEnum.Hks:
        return '北陸・甲信越';
      case AreaInfoIdEnum.Chushi:
        return '中国・四国';
      case AreaInfoIdEnum.Kyuoki:
        return '九州・沖縄';
      case AreaInfoIdEnum.Shizuoka:
        return '静岡';
      case AreaInfoIdEnum.Miyagi:
        return '宮城';
      case AreaInfoIdEnum.Hiroshima:
        return '広島';
      case AreaInfoIdEnum.Fukuoka:
        return '福岡';
      default:
        return '指定なし';
    }
  };

  init();

  return {
    convertToIsConversion15sec,
    convertToViewingType,
    convertToCmType,
    convertToCmDuration,
    convertToStationName,
    convertToTargetName,
    convertToAreaName
  };
};
