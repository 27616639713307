<template>
  <div class="checkbox-button-group" :class="{ disabled }">
    <div v-for="item in items" :key="item.id" class="checkbox-content">
      <template v-if="item.tooltipContent">
        <Tooltip :content="item.tooltipContent">
          <input
            type="checkbox"
            v-model="checkList"
            :value="item.id"
            :disabled="disabled"
            class="checkbox-input"
            @change="onChangeHandler"
          />
          <label class="checkbox-lebel" :class="size">{{ item.label }}</label>
        </Tooltip>
      </template>
      <template v-else>
        <input
          type="checkbox"
          v-model="checkList"
          :value="item.id"
          :disabled="disabled"
          class="checkbox-input"
          @change="onChangeHandler"
        />
        <label class="checkbox-lebel" :class="size">{{ item.label }}</label>
      </template>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import Tooltip from '@/components/ui/Tooltip.vue';

export default defineComponent({
  name: 'CheckboxButtonGroup',
  components: {
    Tooltip
  },
  props: {
    modelValue: Array,
    /**
     * items
     * {
     *   label: string,
     *   tooltipContent: string
     * }[]
     */
    items: Array,
    /**
     * size
     * m | s | xs
     */
    size: {
      type: String,
      default: 's'
    },
    disabled: Boolean
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const checkList = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    return {
      checkList,
      onChangeHandler: () => emit('change', checkList.value)
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/effect';
@import '@/styles/spacing';

.checkbox-button-group {
  display: inline-flex;
  align-items: center;
  background-color: var(--contrast-color);
  border: 0.0625em solid var(--primary-color);
  border-radius: 20px;
  color: var(--primary-color);
  overflow: hidden;

  .checkbox-content {
    position: relative;

    &:last-child {
      .checkbox-lebel {
        border-right: 0;
      }
    }

    &:active {
      opacity: var(--action-opacity);
    }

    @extend .hover-background;
  }

  &.disabled {
    border-color: var(--form-disabled-color);

    .checkbox-content {
      border-color: var(--form-disabled-color);

      &:hover {
        background-color: transparent;
        opacity: 1;
      }
    }
  }

  .checkbox-lebel {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    line-height: 1;
    width: 100%;
    height: 100%;

    &.m {
      font-size: 14px;
      height: 40px;
      padding: 0 $spacing-base * 4;
    }

    &.s {
      font-size: 12px;
      height: 32px;
      padding: 0 $spacing-base * 4;
    }

    &.xs {
      font-size: 12px;
      height: 28px;
      padding: 0 $spacing-base * 3;
    }
  }

  .checkbox-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    + label {
      border-right: 1px solid var(--primary-color);
    }

    &:hover:not(:disabled) {
      cursor: pointer;
    }

    &:checked {
      + label {
        background-color: var(--primary-color);
        color: var(--contrast-color);
        border-right-color: var(--contrast-color);
      }
    }

    &:disabled {
      + label {
        color: var(--form-disabled-color);
        background-color: var(--background-disabled-color);
      }
    }
  }
}
</style>
