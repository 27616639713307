<template>
  <div v-bind="$attrs">
    <Flex gap="16px" :style="{ minHeight }">
      <Box
        v-if="label"
        :min-width="labelWidth"
        :class="[`labelPosition-${labelPosition}`]"
      >
        <Flex :style="{ minHeight }" align-center>
          <Label
            bold
            size="m"
            :required="required"
            :caption="caption"
            :disabled="disabled"
            :color="labelColor"
          >
            {{ label }}
          </Label>
          <Tooltip v-if="tooltip && discription" :content="discription">
            <Icon
              :name="discriptionIcon"
              :sides="discriptionIconSides"
              color="primary"
              style="display:block"
            />
          </Tooltip>
          <PaidPr
            v-if="!tooltip && discription"
            class="formcol"
            :class="$attrs.class"
          >
            <Icon
              :name="discriptionIcon"
              :sides="discriptionIconSides"
              color="primary"
              style="display:block"
            />
            <template v-slot:original>
              <p v-html="discription" />
            </template>
          </PaidPr>
        </Flex>
      </Box>
      <Loading v-if="isLoading" class="loading-icon" />
      <slot v-else />
    </Flex>
    <Flex
      gap="16px"
      :style="{ minHeight: showDetail ? '1em' : undefined }"
      class="mt-1"
    >
      <Box :min-width="labelWidth" />
      <ErrorMessage v-if="!isLoading && errorMessage">
        {{ errorMessage }}
      </ErrorMessage>
    </Flex>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Box from '@/components/layout/Box.vue';
import Label from '@/components/ui/Label.vue';
import ErrorMessage from '@/components/ui/ErrorMessage.vue';
import PaidPr from '@/components/ui/PaidPr.vue';
import Icon from '@/components/ui/Icon.vue';
import Loading from '@/components/ui/Loading.vue';
import Tooltip from '@/components/ui/Tooltip.vue';

export default defineComponent({
  name: 'FormCol',
  components: {
    Flex,
    Box,
    Label,
    ErrorMessage,
    PaidPr,
    Icon,
    Loading,
    Tooltip
  },
  props: {
    disabled: Boolean,
    label: String,
    labelWidth: {
      type: String,
      default: '100px'
    },
    labelPosition: {
      type: String,
      default: 'middle'
    },
    labelColor: {
      type: String,
      default: ''
    },
    required: Boolean,
    caption: String,
    errorMessage: String,
    discription: String,
    discriptionIcon: {
      type: String,
      default: 'question'
    },
    discriptionIconSides: {
      type: String,
      default: '14'
    },
    showDetail: Boolean,
    minHeight: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
.labelPosition- {
  &top {
    align-self: flex-start;
  }
  &middle {
    align-self: center;
  }
}
</style>
