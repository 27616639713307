import { TARGET_NAME } from '@/common/constant';

export const DS_TARGET_NAME = {
  individual_and_household: 'INDIVIDUAL_AND_HOUSEHOLD',
  genderAge12Type: 'BASIC_GA12',
  gender10Type: 'BASIC_GA10S',
  customTarget: 'CUSTOM'
} as const;

export type dsTargetType =
  | 'INDIVIDUAL_AND_HOUSEHOLD'
  | 'BASIC_GA12'
  | 'BASIC_GA10S'
  | 'CUSTOM';

export const convertToTargetType = (name: string): dsTargetType => {
  switch (name) {
    case TARGET_NAME.individual:
    case TARGET_NAME.household:
    case TARGET_NAME.individualAndHousehold:
      return DS_TARGET_NAME.individual_and_household;
    case TARGET_NAME.genderAge12Type:
      return DS_TARGET_NAME.genderAge12Type;
    case TARGET_NAME.gender10Type:
      return DS_TARGET_NAME.gender10Type;
    case TARGET_NAME.customTarget:
      return DS_TARGET_NAME.customTarget;
    default:
      return DS_TARGET_NAME.individual_and_household;
  }
};
