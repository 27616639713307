<template>
  <Flex align-center :inline="!block" class="ui-checkbox" v-ripple>
    <div class="ui-checkbox-view" :class="{ checked, disabled }" />
    <slot name="left" />
    <Label v-if="label" size="m" class="ml-2" :class="{ disabled }">
      {{ label }}
    </Label>
    <slot />
  </Flex>
</template>
<script>
import { defineComponent, ref } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Label from '@/components/ui/Label.vue';

export default defineComponent({
  name: 'BaseCheckbox',
  components: { Flex, Label },
  props: {
    checked: Boolean,
    disabled: Boolean,
    label: String,
    block: Boolean,
    buttonHeight: {
      type: String,
      default: '16px'
    }
  },

  setup(props) {
    const effectiveHeight = ref(props.buttonHeight);
    return {
      effectiveHeight
    };
  }
});
</script>
<style lang="scss">
.ui-checkbox {
  position: relative;

  input,
  button {
    width: 100%;
    height: v-bind(effectiveHeight);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:hover:not(:disabled) {
      cursor: pointer;
    }
  }
}
.ui-checkbox-view {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid var(--border-color);
  border-radius: 4px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 1px;
    width: 10px;
    height: 6px;
    border-left: 2px solid var(--contrast-color);
    border-bottom: 2px solid var(--contrast-color);
    transform: scale(0) rotate(-45deg);
    transition: transform 0.1s linear;
  }

  &.checked {
    background-color: var(--background-focus-color);
    border-color: var(--border-focus-color);
    &::after {
      transform: scale(1) rotate(-45deg);
    }
  }

  &.disabled {
    background-color: var(--background-disabled-color);
    border-color: var(--border-disabled-color);
    cursor: default;
    &.checked {
      background-color: var(--background-disabled-color);
      &::after {
        border-left: 2px solid var(--dark-25-color);
        border-bottom: 2px solid var(--dark-25-color);
      }
    }

    &:hover {
      cursor: default;
    }
  }
}
</style>
