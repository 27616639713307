import { AreaInfoIdEnum } from '@/api/openapi';
import {
  getAvailableDate,
  getAvailableEndDate,
  getAvailableEndDateForProgram,
  getAvailableEndDateForTime
} from '@/common/dates';
import { DATE_FORMAT } from '@/common/format';
import useLoading from '@/composables/loading';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { addDays, differenceInDays, format, subDays } from 'date-fns';
import { computed, ComputedRef, ref, Ref, watch } from 'vue';

export interface useDurationType {
  durationRef: Ref<DateRange | undefined>;
  calendarId: ComputedRef<string>;
  minDate: Ref<Date>;
  maxDate: Ref<Date>;
  durationValid: ComputedRef<string>;
  isLoadingDuration: Ref<boolean>;
  initDuration: () => void;
  getAreaInitDate: (viewingType: string) => DateRange;
  setCurrentEnd: () => void;
  setCurrentStart: () => void;
  calcDaysDifference: () => number;
}

export const DataType = {
  CM: 'CM',
  PROGRAM: '番組',
  TIME: '時間帯'
};

export type DataType = typeof DataType[keyof typeof DataType];

const getFormattedTime = () => format(new Date(), 'HH:mm');

const getInitialDateRange = (endDate: Date, weeksAmount = 6) => {
  return {
    start: subDays(endDate, weeksAmount),
    end: endDate
  };
};

export const useDuration = (
  areaValue: Ref<AreaInfoIdEnum | undefined>,
  dataType?: DataType,
  viewingType?: Ref<string>
): useDurationType => {
  const isTimeOverDateTOTAL = ref(getFormattedTime() >= '05:00');

  const minDate = ref(getAvailableDate(areaValue.value as AreaInfoIdEnum));
  const maxDate = ref(getAvailableEndDate(areaValue.value as AreaInfoIdEnum));

  const durationRef = ref<DateRange>(getInitialDateRange(maxDate.value));
  const durationValid = computed(() => {
    if (calcDaysDifference() >= 371) {
      return '期間は371日間以下で選択してください';
    }
    if (!durationRef?.value?.start || !durationRef?.value?.end) {
      return '';
    }
    return '';
  });

  const calendarId = computed(() => `${areaValue.value}-calendar`);

  const getAvailableEndDateByDataType = (): Date => {
    switch (dataType) {
      case DataType.CM:
        return getAvailableEndDate(areaValue.value as AreaInfoIdEnum);
      case DataType.PROGRAM:
        return getAvailableEndDateForProgram(areaValue.value as AreaInfoIdEnum);
      case DataType.TIME:
        return getAvailableEndDateForTime();
      default:
        return getAvailableEndDate(areaValue.value as AreaInfoIdEnum);
    }
  };

  const getAreaInitDate = (viewingType?: string) => {
    // 初期値はエリア・視聴種別・時間帯によって変わる
    if (viewingType === 'TOTAL') {
      // 視聴種別の初期値がトータルの場合は、期間設定の初期値を変更
      const limitDate = isTimeOverDateTOTAL.value ? 9 : 10;
      const endDate = subDays(new Date(), limitDate);
      return getInitialDateRange(endDate);
    }
    const endDate = getAvailableEndDateByDataType();
    return getInitialDateRange(endDate);
  };

  const setCurrentEnd = () => {
    const currentStart = durationRef.value?.start ?? new Date();
    const currentEnd = durationRef.value?.end ?? new Date();
    if (currentEnd > maxDate.value) {
      durationRef.value = {
        start: currentStart,
        end: maxDate.value
      };
    }
    const newCurrentEnd = durationRef.value?.end ?? new Date();
    if (currentStart > newCurrentEnd) {
      durationRef.value = {
        start: subDays(newCurrentEnd, 6),
        end: newCurrentEnd
      };
    }
  };

  const setCurrentStart = () => {
    const currentStart = durationRef.value?.start ?? new Date();
    const currentEnd = durationRef.value?.end ?? new Date();
    if (currentStart < minDate.value) {
      durationRef.value = {
        start: minDate.value,
        end: currentEnd
      };
    }
    const newCurrentStart = durationRef.value?.start ?? new Date();
    if (newCurrentStart > currentEnd) {
      durationRef.value = {
        start: newCurrentStart,
        end: addDays(newCurrentStart, 6)
      };
    }
  };

  const calcDaysDifference = (): number => {
    if (durationRef.value?.start && durationRef.value?.end) {
      return differenceInDays(durationRef.value.end, durationRef.value.start);
    } else {
      return -1;
    }
  };

  const _change = async () => {
    durationRef.value = getAreaInitDate(viewingType?.value.toString());
  };
  const [isLoadingDuration, change] = useLoading(_change);

  const initDuration = () => {
    change({});
  };

  (async () => {
    await change({});
  })();

  // 選択可能期間最大値変更
  watch(maxDate, setCurrentEnd);
  // 選択可能期間最小値変更
  watch(minDate, setCurrentStart);

  // 視聴種別: トータルの場合のmin/max初期値設定
  watch([viewingType, areaValue], () => {
    if (viewingType?.value === 'TOTAL') {
      const newMin = new Date('2022-12-26');
      // 日界5:00の設定
      const limitDate = isTimeOverDateTOTAL.value ? 9 : 10;
      const newMax = subDays(new Date(), limitDate);
      // 2022-12-26以前を選択できないようにする
      minDate.value = newMin;
      // 9日前以降を選択できないようにする
      maxDate.value = newMax;
    } else {
      // もとに戻す
      minDate.value = getAvailableDate(areaValue.value);
      maxDate.value = getAvailableEndDateByDataType();
    }
  });

  return {
    durationRef,
    durationValid,
    calendarId,
    minDate,
    maxDate,
    isLoadingDuration,
    initDuration,
    getAreaInitDate,
    setCurrentEnd,
    setCurrentStart,
    calcDaysDifference
  };
};

export const formatDuration = (
  durationValue: Ref<DateRange | undefined>
): string[] => {
  if (durationValue.value) {
    return [
      format(durationValue.value.start ?? new Date(), DATE_FORMAT),
      format(durationValue.value.end ?? new Date(), DATE_FORMAT)
    ];
  }
  return [];
};
