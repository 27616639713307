<template>
  <div class="paidPr-inner">
    <Typography line-height="1.4">
      ご利用にはメンバー以上の権限が<br />
      必要です。オーナーまたは管理者<br />
      にお問い合わせください。
    </Typography>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'RestrictedPrInner',
  components: { Typography }
});
</script>
