import {
  CompanyApi,
  CustomTargetApi,
  TvDataSearchApi,
  CmCategoryApi
} from '@/api';
import {
  AreaInfoIdEnum,
  CustomTarget,
  KeyStation,
  SampleSize,
  SelectableAreasList,
  TvDataSearchCmListHistory,
  TvDataSearchCmSponsor,
  TvDataSearchProduct,
  TvDataSearchProgram,
  TvDataSearchCmListHistoryViewingTypeEnum,
  WorkspaceFeatureOptionTypeEnum,
  CompanySettings,
  CmCategory
} from '@/api/openapi';
import { TARGET_NAME } from '@/common/constant';
import {
  DATE_FORMAT,
  DATE_FORMAT_SLASH,
  DATE_FORMAT_SLASH_AND_TIME
} from '@/common/format';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import { useDuration, DataType } from '@/composables/duration';
import { useSummary } from '@/composables/campaign/brand_lift/analysis';
import { useDataTable } from '@/composables/datasearch/cmlist/datatable';
import {
  DataTabelType,
  useHistoryModal
} from '@/composables/datasearch/historymodal/historyModal';
import { convertToTargetType } from '@/composables/datasearch/targets';
import useLoading from '@/composables/loading';
import { COMPANY_ROUTES } from '@/router';
import { useAreaOptions } from '@/store/areaOptions';
import axios from 'axios';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { storeToRefs } from 'pinia';
import { Ref, computed, inject, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useUserInfoStore } from '@/store/userInfo';
import { DateRange } from '@/components/ui/DatePicker.vue';

interface useCmListDataType {
  dt: Ref;
  textMatchModes: Ref<[{ label: string; value: string }]>;
  numberFilterMatchModes: Ref<[{ label: string; value: string }]>;
  filters: Ref;
  breadcrumbs: Ref<{
    parents: { name: string; label: string }[];
    current: { label: string };
  }>;
  isSubMenuOpen: Ref<boolean>;
  isDataLoading: Ref<boolean>;
  isCsvLoading: Ref<boolean>;
  isDisabled: Ref<boolean>;
  isOpenTable: Ref<boolean>;
  isOpenModal: Ref<boolean>;
  isInvalidDate: Ref<boolean>;
  isZero: Ref<boolean>;
  startTimeValue: Ref<{ HH: string; mm: string }>;
  endTimeValue: Ref<{ HH: string; mm: string }>;
  areaValue: Ref<AreaInfoIdEnum>;
  convertArea: Ref<string>;
  cmSponsorsValue: Ref<TvDataSearchCmSponsor>;
  cmProductsValue: Ref<TvDataSearchProduct>;
  cmCategoryValue: Ref<{ id: number; tag: string }[]>;
  cmCategoryData: Ref<CmCategory[]>;
  cmTypeValue: Ref<'TIME' | 'SPOT' | undefined>;
  cmTimeValue: Ref<number | undefined>;
  isConversion15secValue: Ref<boolean>;
  isConversion15secDisabled: Ref<boolean>;
  tvStations: Ref<KeyStation[]>;
  stationsValue: Ref<string[]>;
  stationsValid: Ref<string>;
  tvProgramsValue: Ref<TvDataSearchProgram>;
  viewingTypeValue: Ref<TvDataSearchCmListHistoryViewingTypeEnum>;
  cmLists: Ref<[] | undefined>;
  convertCreateReport: Ref<Date>;
  cmTargetKey: Ref<string[] | undefined>;
  cmTargetWidth: Ref<number>;
  cmSampleSize: Ref<SampleSize[]>;
  targetName: Ref<string>;
  initialTargetId: Ref<number[]>;
  targetValid: Ref<string>;
  durationRef: Ref<DateRange | undefined>;
  convertDataTableDate: Ref<string>;
  durationValid: Ref<string>;
  calendarId: Ref<string>;
  minDate: Ref<Date>;
  maxDate: Ref<Date>;
  isLoadingDuration: Ref<boolean>;
  selectHour: number[];
  convertTimeValid: Ref<string | undefined>;
  isLoading: Ref<boolean>;
  isFetchingHistory: Ref<boolean>;
  isDeletingHistory: Ref<boolean>;
  selectOptions: Ref<SelectableAreasList[]>;
  customTargetList: Ref<
    { id: number; label: string; isEnabled?: boolean | undefined }[]
  >;
  isLoadingOnChangeArea: Ref<boolean>;
  historyData: Ref<DataTabelType | undefined>;
  historyKey: Ref<number>;
  fetchCmSponsors: (args: string) => Promise<Array<TvDataSearchCmSponsor>>;
  fetchProducts: (args: string) => Promise<Array<TvDataSearchProduct>>;
  fetchPrograms: (args: string) => Promise<Array<TvDataSearchProgram>>;
  fetchHistory: (params: unknown) => Promise<void>;
  deleteHistory: (args: number) => Promise<boolean>;
  openHistoryModal: () => void;
  setHistory: (num: number) => void;
  convertViewingRateData: (data: string) => string;
  onChangeArea: (params: unknown) => Promise<void>;
  onChangeTarget: (target: { name: string; value: { id: string } }) => void;
  onClickShowDataTable: () => Promise<void>;
  onClickExportCSV: () => Promise<void>;
  isTotalOptions: Ref<boolean>;
}

export const useCmListData = (): useCmListDataType => {
  const { textMatchModes, numberFilterMatchModes, filters } = useDataTable();
  const areaOptionStore = useAreaOptions();
  const route = useRoute();

  // ベースデータ
  const breadcrumbs = ref({
    parents: [
      { name: COMPANY_ROUTES.top, label: 'ホーム' },
      { name: '', label: 'TVデータサーチ' }
    ],
    current: { label: 'CMリスト' }
  });
  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const isSubMenuOpen = ref(false);
  const isDataLoading = ref(false);
  const isCsvLoading = ref(false);
  const isDisabled = ref(false);
  const isOpenTable = ref(true);
  const isOpenModal = ref(false);
  const isInvalidDate = ref(false);
  const isZero = ref(true);
  const areaValue = ref();
  const isTotalOptions = ref(false);
  const companySetting = ref({} as CompanySettings);
  // 視聴種別
  const viewingTypeValue = ref<TvDataSearchCmListHistoryViewingTypeEnum>(
    TvDataSearchCmListHistoryViewingTypeEnum.Realtime
  );

  // 期間
  const {
    durationValid,
    durationRef,
    minDate,
    maxDate,
    calendarId,
    isLoadingDuration,
    initDuration
  } = useDuration(areaValue, DataType.CM, viewingTypeValue);
  const convertDataTableDate = ref();

  // 検索履歴
  const baseHistoryData: Ref<TvDataSearchCmListHistory[]> = ref([]);
  const historyData = ref();
  const {
    convertToCmType,
    convertToCmDuration,
    convertToStationName,
    convertToTargetName,
    convertToAreaName,
    convertToIsConversion15sec,
    convertToViewingType
  } = useHistoryModal(companyId.value);

  // 時間帯
  const selectHour = [
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28
  ];
  const startTimeValue = ref({
    HH: '05',
    mm: '00'
  }); //model
  const endTimeValue = ref({
    HH: '28',
    mm: '59'
  }); //model
  const convertStartTimeValue = computed(() => {
    return Object.values(startTimeValue.value).join(':');
  });
  const convertEndTimeValue = computed(() => {
    return Object.values(endTimeValue.value).join(':');
  });
  const convertTimeValid = computed(() => {
    if (
      startTimeValue.value.HH &&
      startTimeValue.value.mm &&
      endTimeValue.value.HH &&
      endTimeValue.value.mm
    ) {
      if (convertStartTimeValue.value >= convertEndTimeValue.value) {
        return '終了時刻は開始時刻より後にしてください。';
      } else {
        return;
      }
    } else {
      return '時刻を入力してください。';
    }
  });

  // エリア
  const { isLoading, selectOptions } = storeToRefs(areaOptionStore);

  // 企業名
  const cmSponsorsValue = ref(); // model
  const sponsorsIds = computed(() => {
    if (cmSponsorsValue.value) {
      return cmSponsorsValue.value.map(val => val.id);
    } else {
      return undefined;
    }
  });
  const sponsorsName = computed(() => {
    if (cmSponsorsValue.value) {
      return cmSponsorsValue.value.map(val => val.name);
    } else {
      return undefined;
    }
  });

  // 商品／ブランド
  const cmProductsValue = ref(); // model
  const productsIds = computed(() => {
    if (cmProductsValue.value) {
      return cmProductsValue.value.map(val => val.id);
    } else {
      return undefined;
    }
  });
  const productsName = computed(() => {
    if (cmProductsValue.value) {
      return cmProductsValue.value.map(val => val.name);
    } else {
      return undefined;
    }
  });

  // ターゲット
  const targetName = ref('個人全体・世帯');
  const customTargets = ref<CustomTarget[]>([]);
  const basicTargetIds = ref<number[]>([]);
  const initialTargetId = ref<number[]>([]);
  const customTargetId = ref<number[]>([]);
  const customTargetList = computed(() => {
    return customTargets.value.map(({ id, name, isEnabled }) => ({
      id,
      label: name,
      isEnabled
    }));
  });
  const targetValid = ref<string>('');
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTarget = (target: { name: string; value: any }) => {
    targetName.value = target.name;
    // 個人全体・世帯の場合
    if (targetName.value === TARGET_NAME.individualAndHousehold) {
      isCustomTarget.value = false;
      basicTargetIds.value = [];
      customTargetId.value = [];
      targetValid.value = '';
      return;
    }
    // カスタムターゲットの場合
    if (targetName.value === TARGET_NAME.customTarget) {
      isCustomTarget.value = true;
      if (Array.isArray(target.value)) {
        if (target.value.length > 0) {
          customTargetId.value = target.value.map(v =>
            v instanceof Object ? v.id : v
          );
        } else {
          customTargetId.value = [];
        }
      } else if (target.value) customTargetId.value = target.value.id;
      targetValid.value =
        customTargetId.value.length === 0
          ? 'いずれか一つは選択してください。'
          : '';
      return;
    }
    // 基本属性ターゲットの場合
    isCustomTarget.value = false;
    if (target.value) basicTargetIds.value = target.value;
    targetValid.value =
      basicTargetIds.value.length === 0
        ? 'いずれか一つは選択してください。'
        : '';
  };

  // 放送局
  const tvStations = ref<Array<KeyStation>>([]);
  const stationsValue = ref<Array<string>>([]); // model
  const _onChangeArea = async () => {
    const stations = await TvDataSearchApi.getTvdataSearchStations(
      areaValue.value,
      companyId.value,
      true
    );
    tvStations.value = stations.data;
    stationsValue.value = tvStations.value.map(
      value => value.displayStationName
    );
  };
  const [isLoadingOnChangeArea, onChangeArea] = useLoading(_onChangeArea);

  const getStationValue = (): Array<string> => {
    return tvStations.value
      .filter(value => stationsValue.value.includes(value.displayStationName))
      .flatMap(value => value.stationCodes);
  };

  const stationsValid = computed(() => {
    if (stationsValue.value.length === 0) {
      return 'いずれか一つは選択してください。';
    }
    return '';
  }); // valid

  // 基本設定の反映
  watch([companySetting, isTotalOptions], () => {
    // 権限が有効の場合はCompany設定、無効の場合はリアルタイムに設定
    viewingTypeValue.value = isTotalOptions.value
      ? ((companySetting.value
          .viewingType as unknown) as TvDataSearchCmListHistoryViewingTypeEnum)
      : TvDataSearchCmListHistoryViewingTypeEnum.Realtime;
  });

  // 番組名
  const tvProgramsValue = ref(); // model
  const programsIds = computed(() => {
    if (tvProgramsValue.value) {
      return tvProgramsValue.value.map(val => val.programSeriesId);
    } else {
      return undefined;
    }
  });
  const programsName = computed(() => {
    if (tvProgramsValue.value) {
      return tvProgramsValue.value.map(val => val.name);
    } else {
      return undefined;
    }
  });

  // 商品カテゴリ
  const cmCategoryValue = ref<{ id: number; tag: string }[]>([]);
  const cmCategoryData = ref<CmCategory[]>([]);
  const cmCategoryIds = computed(() => {
    if (!cmCategoryValue.value || !cmCategoryValue.value.length) {
      return undefined;
    } else {
      return cmCategoryValue.value.map(v => v.id);
    }
  });
  const convertCmCategoryName = computed(() => {
    if (!cmCategoryValue.value || !cmCategoryValue.value.length) {
      return '指定なし';
    } else {
      return cmCategoryValue.value.map(v => v.tag).join(',');
    }
  });

  // CM種別
  const cmTypeValue = ref<'TIME' | 'SPOT' | undefined>();

  // CM秒数
  const cmTimeValue = ref<number>();

  // CM15秒換算
  const isConversion15secValue = ref<boolean>(true);
  const isConversion15secDisabled = ref(false);
  // CM秒数が15秒の場合はCM15秒換算を「する」に固定し、選択不可にする
  watch(cmTimeValue, v => {
    isConversion15secDisabled.value = v === 15;
    if (v === 15) {
      isConversion15secValue.value = true;
    }
  });
  // CSV
  const { checkConfirmed } = useSummary('1'); // TODO: 引数意味ないため削除か関数作成
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $papa: any = inject('$papa');

  // SearchResultData
  const convertDateTime = ref();
  const convertTime = ref();
  const convertArea = ref();
  const convertSampleSize = ref();
  const convertSponsorsName = ref();
  const convertProductsName = ref();
  const convertCmTime = ref();
  const convertCmType = ref();
  const convertStation = ref();
  const convertProgramsName = ref();
  const convertUseData = ref();
  const convertCreateReport = ref();
  const convertCsvFileDate = ref();
  const convertViewingType = ref();
  const convertIsConversion15sec = ref();

  // CMリスト
  const cmLists = ref(); // API
  const cmListsLength = ref();
  const cmTargetKey = ref();
  const cmTargetWidth = ref();
  const cmSampleSize = ref();
  // やりたくないけど、こうするしかない
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const baseData = ref<Array<any>>([]);
  const viewingRateData = ref();
  const targetIds = ref();
  const isCustomTarget = ref(false); // カスタムターゲットの有無
  const dt = ref();
  const historyKey = ref(0);

  // バリデーション
  const valids = ref({
    duration: false,
    area: false,
    target: false,
    stations: false,
    selectTime: false
  });
  watch(durationValid, () => {
    valids.value.duration = Boolean(durationValid.value);
  }); // 期間
  watch(areaValue, () => {
    valids.value.area = !areaValue.value;
  }); // エリアバリデーション
  watch(targetValid, () => {
    valids.value.target = !!targetValid.value;
  }); // ターゲットバリデーション
  watch(stationsValue, () => {
    valids.value.stations = stationsValue.value.length === 0;
  }); // 放送局バリデーション
  watch([startTimeValue, endTimeValue], () => {
    valids.value.selectTime =
      convertStartTimeValue.value >= convertEndTimeValue.value;
  }); // 時間帯バリデーション
  watch(
    valids,
    v => {
      isDisabled.value = Object.values(v).includes(true);
    },
    { deep: true }
  ); //バリデーションまとめ

  // CMリスト取得
  const onClickShowDataTable = async () => {
    try {
      isDataLoading.value = true;

      // フィルター、ソート、ページネーションリセット
      const filters = dt.value.filters;
      filters.cmProductSummary.constraints[0].value = null;
      filters.cmSituation.constraints[0].value = null;
      filters.cmSponsor.constraints[0].value = null;
      filters.cmType.constraints[0].value = null;
      filters.programName.constraints[0].value = null;
      filters.cmCategoryLevel1Category.constraints[0].value = null;
      filters.cmCategoryLevel2Category.constraints[0].value = null;
      filters.cmCategoryLevel3Category.constraints[0].value = null;
      filters.station.constraints[0].value = null;
      dt.value.d_sortField = null;
      dt.value.d_sortOrder = null;
      dt.value.d_first = 0;

      // Valid:企業、商品、番組、商品カテゴリの有無チェック
      if (
        (!sponsorsIds.value || !sponsorsIds.value.length) &&
        (!productsIds.value || !productsIds.value.length) &&
        (!programsIds.value || !programsIds.value.length) &&
        (!cmCategoryIds.value || !cmCategoryIds.value.length)
      ) {
        isOpenTable.value = true;
        throw new Error(
          '企業名、商品／ブランド、番組名、商品カテゴリのいずれかを指定してください。'
        );
      }
      // Valid：放送曲の有無チェック
      if (!stationsValue.value.length) {
        isOpenTable.value = true;
        throw new Error('放送局を選択してください。');
      }

      // ターゲットID設定
      if (isCustomTarget.value) {
        targetIds.value = customTargetId.value;
      } else {
        targetIds.value = basicTargetIds.value;
        // ターゲットID並び替え設定
        if (targetIds.value) {
          targetIds.value.sort(function(a, b) {
            return a - b;
          });
        }
      }

      // 各種データの取得
      await Promise.all([fetchSampleSize(), fetchCmList()]);

      // 商品名抽出
      const cmProductSummary = baseData.value.map(val => {
        return val.cmProductAfterSummary === ''
          ? val.cmProductBeforeSummary
          : val.cmProductAfterSummary;
      });

      // ソート用曜日num生成
      const dayOfWeekNum = baseData.value.map(val => {
        switch (val.dayOfWeek) {
          case '月':
            return 1;
          case '火':
            return 2;
          case '水':
            return 3;
          case '木':
            return 4;
          case '金':
            return 5;
          case '土':
            return 6;
          case '日':
            return 7;
        }
      });

      // ソート用カテゴリ生成
      const cmCategoryLevel1Category = baseData.value.map(val => {
        return val.cmCategory.level1Category.name;
      });

      const cmCategoryLevel2Category = baseData.value.map(val => {
        return val.cmCategory.level2Category?.name ?? '';
      });

      const cmCategoryLevel3Category = baseData.value.map(val => {
        return val.cmCategory.level3Category?.name ?? '';
      });

      // カテゴリID生成
      const cmCategoryId = baseData.value.map(val => {
        if (val.cmCategory.level3Category) {
          return val.cmCategory.level3Category.id;
        } else if (val.cmCategory.level2Category) {
          return val.cmCategory.level2Category.id;
        } else if (val.cmCategory.level1Category) {
          return val.cmCategory.level1Category.id;
        }
      });

      // CMリスト結合
      cmLists.value = baseData.value.map((val, i) => {
        val.cmProductSummary = cmProductSummary[i];
        val.dayOfWeekNum = dayOfWeekNum[i];
        val.cmCategoryId = cmCategoryId[i];
        val.cmCategoryLevel1Category = cmCategoryLevel1Category[i];
        val.cmCategoryLevel2Category = cmCategoryLevel2Category[i];
        val.cmCategoryLevel3Category = cmCategoryLevel3Category[i];
        delete val.cmCategory;
        return { ...val, ...viewingRateData.value[i] };
      });

      if (cmLists.value.length) {
        // CMリストLength取得
        cmListsLength.value = Object.keys(cmLists.value[0]).length;
        // ターゲットkey取得
        cmTargetKey.value = Object.keys(viewingRateData.value[0]);
        // ターゲットWidthを動的に生成
        cmTargetWidth.value = cmTargetKey.value.length * 150;
      }

      // データテーブル：期間
      const convertDate = computed(() => {
        if (durationRef?.value?.start && durationRef?.value?.end) {
          const start = format(durationRef?.value?.start, 'yyyy年MM月dd日(E)', {
            locale: ja
          });
          const end = format(durationRef?.value?.end, 'yyyy年MM月dd日(E)', {
            locale: ja
          });
          return [start, end].join('~');
        } else {
          return '';
        }
      });
      convertDataTableDate.value = convertDate.value;

      // CSV：SearchResultData

      // 検索条件の設定：期間
      if (durationRef?.value?.start && durationRef?.value?.end) {
        const start = format(durationRef?.value?.start, 'yyyy年MM月dd日(E)', {
          locale: ja
        });
        const end = format(durationRef?.value?.end, 'yyyy年MM月dd日(E)', {
          locale: ja
        });
        convertDateTime.value = [start, end].join('~');
      } else {
        convertDateTime.value = '';
      }

      // 検索条件の設定：時間帯
      if (convertStartTimeValue.value && convertEndTimeValue.value) {
        convertTime.value = [
          convertStartTimeValue.value,
          convertEndTimeValue.value
        ].join('~');
      } else {
        convertTime.value = '';
      }

      // 検索条件の設定：エリア
      switch (areaValue.value) {
        case AreaInfoIdEnum.Kanto:
          convertArea.value = '関東';
          break;
        case AreaInfoIdEnum.Kansai:
          convertArea.value = '関西';
          break;
        case AreaInfoIdEnum.Chukyo:
          convertArea.value = '中京';
          break;
        case AreaInfoIdEnum.Hokkaido:
          convertArea.value = '北海道';
          break;
        case AreaInfoIdEnum.Tohoku:
          convertArea.value = '東北';
          break;
        case AreaInfoIdEnum.Hks:
          convertArea.value = '北陸・甲信越';
          break;
        case AreaInfoIdEnum.Chushi:
          convertArea.value = '中国・四国';
          break;
        case AreaInfoIdEnum.Kyuoki:
          convertArea.value = '九州・沖縄';
          break;
        case AreaInfoIdEnum.Shizuoka:
          convertArea.value = '静岡';
          break;
        case AreaInfoIdEnum.Miyagi:
          convertArea.value = '宮城';
          break;
        case AreaInfoIdEnum.Hiroshima:
          convertArea.value = '広島';
          break;
        case AreaInfoIdEnum.Fukuoka:
          convertArea.value = '福岡';
          break;
      }

      // 検索条件の設定：サンプルサイズ
      const getSampleSizeData = cmSampleSize.value.map(val => {
        const sampleSize = val.sampleSize.toLocaleString();
        return [val.name, sampleSize].join(' ');
      });
      convertSampleSize.value = getSampleSizeData.join();

      // 検索条件の設定：企業名
      if (!sponsorsName.value || sponsorsName.value.length === 0) {
        convertSponsorsName.value = '指定なし';
      } else {
        convertSponsorsName.value = sponsorsName.value;
      }

      // 検索条件の設定：商品／ブランド
      if (!productsName.value || productsName.value.length === 0) {
        convertProductsName.value = '指定なし';
      } else {
        convertProductsName.value = productsName.value;
      }

      // 検索条件の設定：視聴種別
      switch (viewingTypeValue.value) {
        case TvDataSearchCmListHistoryViewingTypeEnum.Realtime:
          convertViewingType.value = 'リアルタイム';
          break;
        case TvDataSearchCmListHistoryViewingTypeEnum.Total:
          convertViewingType.value = 'トータル';
          break;
        default:
          convertViewingType.value = 'リアルタイム';
          break;
      }

      // 検索条件の設定：CM秒数
      switch (String(cmTimeValue.value)) {
        case 'undefined':
          convertCmTime.value = '全CM';
          break;
        case '15':
          convertCmTime.value = '15秒';
          break;
        case '30':
          convertCmTime.value = '30秒以上';
          break;
      }

      // 検索条件の設定：CM種別
      switch (cmTypeValue.value) {
        case undefined:
          convertCmType.value = '全CM';
          break;
        case 'TIME':
          convertCmType.value = 'タイム';
          break;
        case 'SPOT':
          convertCmType.value = 'スポット';
          break;
      }
      // 検索条件の設定：CM15秒換算
      convertIsConversion15sec.value = isConversion15secValue.value
        ? 'する'
        : 'しない';

      // 検索条件の設定：放送局
      convertStation.value = stationsValue.value;

      // 検索条件の設定：番組名
      if (!programsName.value || programsName.value.length === 0) {
        convertProgramsName.value = '指定なし';
      } else {
        convertProgramsName.value = programsName.value;
      }

      // 検索条件の設定：利用データ
      if (
        checkConfirmed(
          format(durationRef?.value?.end as Date, DATE_FORMAT),
          AreaInfoIdEnum[areaValue.value]
        ) === true
      ) {
        convertUseData.value = '確報';
      } else {
        convertUseData.value = '速報';
      }

      // 検索条件の設定：レポート作成日時
      convertCreateReport.value = format(
        new Date(),
        'yyyy年MM月dd日(E) HH:mm',
        {
          locale: ja
        }
      );

      // 検索条件の設定：CSVファイルデータ
      if (durationRef?.value?.start && durationRef?.value?.end) {
        const start = format(durationRef?.value?.start, 'yyyyMMdd');
        const end = format(durationRef?.value?.end, 'yyyyMMdd');
        convertCsvFileDate.value = [start, end].join('~');
      } else {
        convertCsvFileDate.value = '';
      }

      isOpenTable.value = false;
    } catch (e) {
      console.error(e);
      if (axios.isAxiosError(e)) {
        handleError(e);
      } else {
        toast({ title: '失敗', message: e.message, variant: 'error' });
      }
    } finally {
      isDataLoading.value = false;
    }
  };

  const fetchSampleSize = async () => {
    const result = await TvDataSearchApi.getTvdataSearchSampleSize(
      areaValue.value,
      isCustomTarget.value,
      companyId.value,
      format(durationRef?.value?.start as Date, DATE_FORMAT),
      format(durationRef?.value?.end as Date, DATE_FORMAT),
      targetIds.value
    );
    cmSampleSize.value = result.data;
  };

  const fetchCmList = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = await TvDataSearchApi.getTvdataSearchCmListSearch(
      getStationValue(),
      format(durationRef?.value?.start as Date, DATE_FORMAT),
      format(durationRef?.value?.end as Date, DATE_FORMAT),
      convertStartTimeValue.value,
      convertEndTimeValue.value,
      areaValue.value,
      convertToTargetType(targetName.value),
      companyId.value,
      isConversion15secValue.value,
      viewingTypeValue.value,
      cmTimeValue.value,
      cmTypeValue.value,
      sponsorsIds.value,
      productsIds.value,
      programsIds.value,
      targetIds.value,
      cmCategoryIds.value
    );
    baseData.value = result.data.baseData;
    viewingRateData.value = result.data.viewingRateData;
    isZero.value = result.data.baseData.length === 0;
  };

  // 基本設定
  const fetchCompanySettings = async () => {
    const { data } = await CompanyApi.getCompaniesCompanyIdSettings(
      companyId.value
    );
    // 基本設定をHistoryで使い回すために保持
    companySetting.value = data;
    // CM15秒換算
    isConversion15secValue.value = data.isConversion15sec;
    // 視聴種別:権限が有効の場合はCompany設定、無効の場合はリアルタイムに設定
    viewingTypeValue.value = isTotalOptions.value
      ? ((data.viewingType as unknown) as TvDataSearchCmListHistoryViewingTypeEnum)
      : TvDataSearchCmListHistoryViewingTypeEnum.Realtime;
  };

  const fetchCmSponsors = async (search: string) => {
    if (
      stationsValue.value.length &&
      durationRef?.value?.start &&
      durationRef?.value?.end &&
      startTimeValue.value &&
      endTimeValue.value &&
      companyId.value
    ) {
      // 企業名
      const getCmSponsors = await TvDataSearchApi.getTvdataSearchCmSponsorsSearch(
        getStationValue(),
        format(durationRef?.value?.start, DATE_FORMAT),
        format(durationRef?.value?.end, DATE_FORMAT),
        convertStartTimeValue.value,
        convertEndTimeValue.value,
        companyId.value,
        cmTimeValue.value,
        cmTypeValue.value,
        sponsorsIds.value,
        productsIds.value,
        programsIds.value,
        search
      );
      return getCmSponsors.data;
    }
    return [];
  };

  const fetchProducts = async (search: string) => {
    if (
      stationsValue.value.length &&
      durationRef?.value?.start &&
      durationRef?.value?.end &&
      startTimeValue.value &&
      endTimeValue.value &&
      companyId.value
    ) {
      // 商品／ブランド
      const getCmProducts = await TvDataSearchApi.getTvdataSearchProductsSearch(
        getStationValue(),
        format(durationRef?.value?.start, DATE_FORMAT),
        format(durationRef?.value?.end, DATE_FORMAT),
        convertStartTimeValue.value,
        convertEndTimeValue.value,
        companyId.value,
        cmTimeValue.value,
        cmTypeValue.value,
        sponsorsIds.value,
        productsIds.value,
        programsIds.value,
        search
      );
      return getCmProducts.data;
    }
    return [];
  };

  const fetchPrograms = async (search: string) => {
    if (
      stationsValue.value.length &&
      durationRef?.value?.start &&
      durationRef?.value?.end &&
      startTimeValue.value &&
      endTimeValue.value &&
      companyId.value
    ) {
      // 番組名取得
      const getTvPrograms = await TvDataSearchApi.getTvdataSearchProgramsSearch(
        getStationValue(),
        format(durationRef?.value?.start, DATE_FORMAT),
        format(durationRef?.value?.end, DATE_FORMAT),
        convertStartTimeValue.value,
        convertEndTimeValue.value,
        companyId.value,
        cmTimeValue.value,
        cmTypeValue.value,
        sponsorsIds.value,
        productsIds.value,
        programsIds.value,
        search
      );
      // 型定義が盛大に誤っているので無理やり修正
      return (getTvPrograms.data as unknown) as Array<TvDataSearchProgram>;
    }
    return [];
  };

  const _fetchHistory = async () => {
    try {
      if (!companyId.value) {
        toast({ title: 'カンパニーが選択されていません', variant: 'error' });
        return;
      }
      const res = await TvDataSearchApi.getTvdataSearchCmListCompaniesCompanyIdHistories(
        companyId.value
      );
      if (200 <= res.status && res.status < 300) {
        baseHistoryData.value = res.data.list;
        historyData.value = convertToHistoryTableData(res.data.list);
      } else {
        toast({
          title: '失敗',
          message: '検索履歴の取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: '検索履歴の取得に失敗しました',
        variant: 'error'
      });
    }
  };
  const [isFetchingHistory, fetchHistory] = useLoading(_fetchHistory);

  const _deleteHistory = async (args: number): Promise<boolean> => {
    try {
      if (!companyId.value) {
        toast({ title: 'カンパニーが選択されていません', variant: 'error' });
        return false;
      }
      const res = await TvDataSearchApi.deleteTvdataSearchCmListHistoriesCmListSearchHistoryId(
        args
      );
      if (200 <= res.status && res.status < 300) {
        return true;
      } else {
        toast({
          title: '失敗',
          message: '検索履歴の削除に失敗しました',
          variant: 'error'
        });
        return false;
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: '検索履歴の削除に失敗しました',
        variant: 'error'
      });
      return false;
    }
  };
  const [isDeletingHistory, deleteHistory] = useLoading(_deleteHistory);

  const convertToHistoryTableData = (
    dataList: TvDataSearchCmListHistory[]
  ): DataTabelType[] => {
    const notSet = '指定なし';
    const deleteTarget = '削除されています';
    return dataList.map(data => {
      const today = new Date();
      const cmProductName =
        data.cmProducts?.map(v => v.cmProductName).join(',') || notSet;
      const cmSponsorName =
        data.cmSponsors?.map(v => v.cmSponsorName).join(',') || notSet;
      const targets =
        data.targets
          ?.map(v =>
            v.targetName
              ? v.targetName
              : !data.isCustomTargetNotDeleted
              ? deleteTarget
              : ''
          )
          .join(',') || TARGET_NAME.individualAndHousehold;
      const startDate = new Date(data.startDate.replace(/-/g, '/')) ?? today;
      const endDate = new Date(data.endDate.replace(/-/g, '/')) ?? today;
      const area = convertToAreaName(data.areaCode);
      const slash = data.searchedAt.replace(/-/g, '/');
      const dotIndex = slash.indexOf('.');
      const searchedAt = new Date(slash.slice(0, dotIndex)) ?? today;

      const baseConditions = {
        cmProductName: cmProductName,
        cmSponsorName: cmSponsorName,
        targets: targets,
        startDate: startDate,
        endDate: endDate,
        area: area,
        searchedAt: searchedAt,
        id: data.id,
        isCustomTargetNotDeleted: data.isCustomTargetNotDeleted,
        isProductUnchanged: data.isProductUnchanged
      };

      const allConditions = {
        ...baseConditions,
        searchedAt: format(searchedAt, DATE_FORMAT_SLASH_AND_TIME),
        buyingKind: convertToCmType(data.buyingKind?.toString()),
        cmDuration: convertToCmDuration(data.cmDuration),
        viewingType: convertToViewingType(data.viewingType),
        isConversion15sec: convertToIsConversion15sec(data.isConversion15sec),
        stations:
          convertToStationName(data.areaCode, data.stations).join(',') ||
          notSet,
        period:
          format(startDate, DATE_FORMAT_SLASH) +
          '〜' +
          format(endDate, DATE_FORMAT_SLASH),
        time: data.startTime + '〜' + data.endTime,
        programSeries:
          data.programSeries?.map(v => v.programName).join(',') || notSet,
        cmCategories: data.cmCategories?.map(v => v.tag).join('、') || notSet
      };

      return { ...baseConditions, allConditions: allConditions };
    });
  };

  const openHistoryModal = () => {
    isOpenModal.value = true;
    fetchHistory('');
  };

  const setHistory = async (num: number) => {
    const history = baseHistoryData.value.find(v => v.id === num);
    if (!history) return;
    // 期間
    durationRef.value = {
      start: new Date(history.startDate.replace(/-/g, '/')),
      end: new Date(history.endDate.replace(/-/g, '/'))
    };
    // エリア
    areaValue.value = history.areaCode;
    await onChangeArea(undefined);
    // 編集データのエリアが契約エリアに含まれているか判定
    const selectableAreaList = selectOptions.value.flatMap(v =>
      v.areas.map(area => area.id)
    );
    if (!selectableAreaList.includes(areaValue.value ?? '')) {
      areaValue.value = undefined;
    }
    // 企業名
    cmSponsorsValue.value = undefined;
    if (history.cmSponsors) {
      const tmpCmSponsorsValue: {
        id: number;
        name: string;
      }[] = [];
      history.cmSponsors.map(v => {
        tmpCmSponsorsValue.push({
          name: v.cmSponsorName ?? '',
          id: v.cmSponsorId ?? 0
        });
      });
      cmSponsorsValue.value = tmpCmSponsorsValue;
    }
    // 商品／ブランド
    cmProductsValue.value = undefined;
    if (history.cmProducts) {
      const tmpCmProductsValue: {
        id: number;
        name: string;
      }[] = [];
      history.cmProducts.map(v => {
        tmpCmProductsValue.push({
          id: v.cmProductId ?? 0,
          name: v.cmProductName ?? ''
        });
      });
      cmProductsValue.value = tmpCmProductsValue;
    }
    // ターゲット
    basicTargetIds.value = [];
    customTargetId.value = [];
    targetValid.value = '';
    isCustomTarget.value = false;
    targetName.value = convertToTargetName(history.targetType);
    if (history.targets) {
      if (targetName.value === TARGET_NAME.customTarget) {
        customTargetId.value = history.targets?.map(v => {
          return v.targetId ?? 0;
        });
        initialTargetId.value = customTargetId.value;
        isCustomTarget.value = true;
      } else if (targetName.value === TARGET_NAME.individualAndHousehold) {
        isCustomTarget.value = false;
      } else {
        basicTargetIds.value = history.targets?.map(v => {
          return v.targetId ?? 0;
        });
        initialTargetId.value = basicTargetIds.value;
        isCustomTarget.value = false;
      }
    }
    // 視聴種別
    viewingTypeValue.value = isTotalOptions.value
      ? history.viewingType ?? undefined
      : TvDataSearchCmListHistoryViewingTypeEnum.Realtime;
    // 15秒換算
    isConversion15secValue.value =
      history.isConversion15sec ?? companySetting.value.isConversion15sec;
    // CM種別
    cmTypeValue.value = history.buyingKind ?? undefined;
    // CM秒数
    cmTimeValue.value = history.cmDuration ?? undefined;
    // 放送局
    stationsValue.value = convertToStationName(
      history.areaCode,
      history.stations
    );
    // 番組名
    tvProgramsValue.value = undefined;
    if (history.programSeries) {
      const tmpTvProgramsValue: {
        programSeriesId: number;
        name: string;
      }[] = [];
      history.programSeries.map(v => {
        tmpTvProgramsValue.push({
          programSeriesId: v.programSeriesId ?? 0,
          name: v.programName ?? ''
        });
      });
      tvProgramsValue.value = tmpTvProgramsValue;
    }
    // 商品カテゴリ
    cmCategoryValue.value = [];
    if (history.cmCategories) {
      const historyCmCategories = history.cmCategories.map(v => ({
        id: v.id,
        tag: v.tag
      }));
      cmCategoryValue.value = historyCmCategories;
    }
    // 時間帯
    startTimeValue.value.HH = history.startTime.split(':')[0];
    startTimeValue.value.mm = history.startTime.split(':')[1];
    endTimeValue.value.HH = history.endTime.split(':')[0];
    endTimeValue.value.mm = history.endTime.split(':')[1];

    isSubMenuOpen.value = true; // 検索オプションを開く
    historyKey.value += 1; // 画面表示更新
  };

  const convertViewingRateData = (data: string) => {
    const mothVal = Math.round(Number(data) * 10) / 10;
    return mothVal.toFixed(1);
  };

  // CSVダウンロード
  const onClickExportCSV = async () => {
    try {
      isCsvLoading.value = true;

      const csvParseResults = ref();
      const target = cmTargetKey.value.map(val => {
        return `視聴率：${val}`;
      });
      const csvData = ref([
        ['TVAL - CMリスト'],
        ['期間:', `${convertDateTime.value}`],
        ['時間帯:', `${convertTime.value}`],
        ['エリア:', `${convertArea.value}`],
        ['期間内最新サンプルサイズ:', `${convertSampleSize.value}`],
        ['企業名:', `${convertSponsorsName.value}`],
        ['商品／ブランド名:', `${convertProductsName.value}`],
        ['CM秒数:', `${convertCmTime.value}`],
        ['CM種別:', `${convertCmType.value}`],
        ['放送局:', `${convertStation.value}`],
        ['番組名:', `${convertProgramsName.value}`],
        ['商品カテゴリ:', `${convertCmCategoryName.value}`],
        ['視聴種別:', `${convertViewingType.value}`],
        ['利用データ:', `${convertUseData.value}`],
        ['15秒換算:', `${convertIsConversion15sec.value}`],
        ['単位:', '%'],
        ['レポート作成日時:', `${convertCreateReport.value}`],
        ['データ提供元:', 'Switch Media, Inc.'],
        [],
        [
          '日付',
          '曜日',
          '祝日',
          '開始時刻',
          'エリア',
          '集計対象都道府県',
          '放送局',
          '放送系列',
          '番組',
          'CM種別',
          '企業',
          '商品／ブランド',
          'CM商品ID',
          'CM商品名',
          '商品カテゴリID',
          '商品大カテゴリ',
          '商品中カテゴリ',
          '商品小カテゴリ',
          '素材ID',
          '秒数',
          'CM内容',
          '出演者',
          'BGM',
          '備考',
          ...target
        ]
      ]);
      const csvDataList = [
        'date',
        'dayOfWeek',
        'isHoliday',
        'startTime',
        'areaName',
        'areaPrefectureNames',
        'station',
        'stationNetwork',
        'programName',
        'cmType',
        'cmSponsor',
        'cmProductAfterSummary',
        'cmProductIdBeforeSummary',
        'cmProductBeforeSummary',
        'cmCategoryId',
        'cmCategoryLevel1Category',
        'cmCategoryLevel2Category',
        'cmCategoryLevel3Category',
        'cmCreativeId',
        'cmDuration',
        'cmSituation',
        'cmTalent',
        'cmBgm',
        'cmRemarks',
        ...cmTargetKey.value
      ];

      const dateValue = dt.value.value;
      const sortField = dt.value.d_sortField;
      const sortOrder = dt.value.d_sortOrder;
      const filters = dt.value.filters;

      // Valid:取得データの有無チェック
      if (!dateValue) {
        throw new Error('データがありません。');
      }

      // カラムフィルター更新
      const basefilterData = ref(JSON.parse(JSON.stringify(dateValue)));
      const cmProductSummaryString =
        filters.cmProductSummary.constraints[0].value;
      const cmProductSummaryMatch =
        filters.cmProductSummary.constraints[0].matchMode;
      const cmSituationString = filters.cmSituation.constraints[0].value;
      const cmSituationMatch = filters.cmSituation.constraints[0].matchMode;
      const cmSponsorString = filters.cmSponsor.constraints[0].value;
      const cmSponsorMatch = filters.cmSponsor.constraints[0].matchMode;
      const cmTypeString = filters.cmType.constraints[0].value;
      const cmTypeMatch = filters.cmType.constraints[0].matchMode;
      const programNameString = filters.programName.constraints[0].value;
      const programNameMatch = filters.programName.constraints[0].matchMode;
      const cmCategoryLevel1CategoryString =
        filters.cmCategoryLevel1Category.constraints[0].value;
      const cmCategoryLevel1CategoryMatch =
        filters.cmCategoryLevel1Category.constraints[0].matchMode;
      const cmCategoryLevel2CategoryString =
        filters.cmCategoryLevel2Category.constraints[0].value;
      const cmCategoryLevel2CategoryMatch =
        filters.cmCategoryLevel2Category.constraints[0].matchMode;
      const cmCategoryLevel3CategoryString =
        filters.cmCategoryLevel3Category.constraints[0].value;
      const cmCategoryLevel3CategoryMatch =
        filters.cmCategoryLevel3Category.constraints[0].matchMode;
      const stationString = filters.station.constraints[0].value;
      const stationMatch = filters.station.constraints[0].matchMode;
      const filterNameList = [
        'cmProductSummary',
        'cmCategoryLevel1Category',
        'cmCategoryLevel2Category',
        'cmCategoryLevel3Category',
        'cmSituation',
        'cmSponsor',
        'cmType',
        'programName',
        'station'
      ];

      // 商品カラムフィルター
      if (cmProductSummaryString) {
        basefilterData.value = basefilterData.value.filter(val => {
          switch (cmProductSummaryMatch) {
            case 'equals':
              return (
                val.cmProductSummary.toUpperCase() ===
                cmProductSummaryString.toUpperCase()
              );
            case 'notEquals':
              return (
                val.cmProductSummary.toUpperCase() !==
                cmProductSummaryString.toUpperCase()
              );
            case 'contains':
              if (
                val.cmProductSummary
                  .toUpperCase()
                  .indexOf(cmProductSummaryString.toUpperCase()) !== -1
              ) {
                return val.cmProductSummary;
              }
              break;
            case 'notContains':
              if (
                val.cmProductSummary
                  .toUpperCase()
                  .indexOf(cmProductSummaryString.toUpperCase()) === -1
              ) {
                return val.cmProductSummary;
              }
              break;
          }
        });
      }
      // 商品カテゴリ_level1フィルター
      if (cmCategoryLevel1CategoryString) {
        basefilterData.value = basefilterData.value.filter(val => {
          switch (cmCategoryLevel1CategoryMatch) {
            case 'equals':
              return (
                val.cmCategoryLevel1Category.toUpperCase() ===
                cmCategoryLevel1CategoryString.toUpperCase()
              );
            case 'notEquals':
              return (
                val.cmCategoryLevel1Category.toUpperCase() !==
                cmCategoryLevel1CategoryString.toUpperCase()
              );
            case 'contains':
              return (
                val.cmCategoryLevel1Category
                  .toUpperCase()
                  .indexOf(cmCategoryLevel1CategoryString.toUpperCase()) !== -1
              );
            case 'notContains':
              return (
                val.cmCategoryLevel1Category
                  .toUpperCase()
                  .indexOf(cmCategoryLevel1CategoryString.toUpperCase()) === -1
              );
          }
        });
      }
      // 商品カテゴリ_level2フィルター
      if (cmCategoryLevel2CategoryString) {
        basefilterData.value = basefilterData.value.filter(val => {
          switch (cmCategoryLevel2CategoryMatch) {
            case 'equals':
              return (
                val.cmCategoryLevel2Category.toUpperCase() ===
                cmCategoryLevel2CategoryString.toUpperCase()
              );
            case 'notEquals':
              return (
                val.cmCategoryLevel2Category.toUpperCase() !==
                cmCategoryLevel2CategoryString.toUpperCase()
              );
            case 'contains':
              return (
                val.cmCategoryLevel2Category
                  .toUpperCase()
                  .indexOf(cmCategoryLevel2CategoryString.toUpperCase()) !== -1
              );
            case 'notContains':
              return (
                val.cmCategoryLevel2Category
                  .toUpperCase()
                  .indexOf(cmCategoryLevel2CategoryString.toUpperCase()) === -1
              );
          }
        });
      }
      // 商品カテゴリ_level3フィルター
      if (cmCategoryLevel3CategoryString) {
        basefilterData.value = basefilterData.value.filter(val => {
          switch (cmCategoryLevel3CategoryMatch) {
            case 'equals':
              return (
                val.cmCategoryLevel3Category.toUpperCase() ===
                cmCategoryLevel3CategoryString.toUpperCase()
              );
            case 'notEquals':
              return (
                val.cmCategoryLevel3Category.toUpperCase() !==
                cmCategoryLevel3CategoryString.toUpperCase()
              );
            case 'contains':
              return (
                val.cmCategoryLevel3Category
                  .toUpperCase()
                  .indexOf(cmCategoryLevel3CategoryString.toUpperCase()) !== -1
              );
            case 'notContains':
              return (
                val.cmCategoryLevel3Category
                  .toUpperCase()
                  .indexOf(cmCategoryLevel3CategoryString.toUpperCase()) === -1
              );
          }
        });
      }
      // CM内容カラムフィルター
      if (cmSituationString) {
        basefilterData.value = basefilterData.value.filter(val => {
          switch (cmSituationMatch) {
            case 'equals':
              return (
                val.cmSituation.toUpperCase() ===
                cmSituationString.toUpperCase()
              );
            case 'notEquals':
              return (
                val.cmSituation.toUpperCase() !==
                cmSituationString.toUpperCase()
              );
            case 'contains':
              if (
                val.cmSituation
                  .toUpperCase()
                  .indexOf(cmSituationString.toUpperCase()) !== -1
              ) {
                return val.cmSituation;
              }
              break;
            case 'notContains':
              if (
                val.cmSituation
                  .toUpperCase()
                  .indexOf(cmSituationString.toUpperCase()) === -1
              ) {
                return val.cmSituation;
              }
              break;
          }
        });
      }
      // 企業カラムフィルター
      if (cmSponsorString) {
        basefilterData.value = basefilterData.value.filter(val => {
          switch (cmSponsorMatch) {
            case 'equals':
              return (
                val.cmSponsor.toUpperCase() === cmSponsorString.toUpperCase()
              );
            case 'notEquals':
              return (
                val.cmSponsor.toUpperCase() !== cmSponsorString.toUpperCase()
              );
            case 'contains':
              if (
                val.cmSponsor
                  .toUpperCase()
                  .indexOf(cmSponsorString.toUpperCase()) !== -1
              ) {
                return val.cmSponsor;
              }
              break;
            case 'notContains':
              if (
                val.cmSponsor
                  .toUpperCase()
                  .indexOf(cmSponsorString.toUpperCase()) === -1
              ) {
                return val.cmSponsor;
              }
              break;
          }
        });
      }
      // CM種別カラムフィルター
      if (cmTypeString) {
        basefilterData.value = basefilterData.value.filter(val => {
          switch (cmTypeMatch) {
            case 'equals':
              return val.cmType.toUpperCase() === cmTypeString.toUpperCase();
            case 'notEquals':
              return val.cmType.toUpperCase() !== cmTypeString.toUpperCase();
            case 'contains':
              if (
                val.cmType.toUpperCase().indexOf(cmTypeString.toUpperCase()) !==
                -1
              ) {
                return val.cmType;
              }
              break;
            case 'notContains':
              if (
                val.cmType.toUpperCase().indexOf(cmTypeString.toUpperCase()) ===
                -1
              ) {
                return val.cmType;
              }
              break;
          }
        });
      }
      // 番組カラムフィルター
      if (programNameString) {
        basefilterData.value = basefilterData.value.filter(val => {
          switch (programNameMatch) {
            case 'equals':
              return (
                val.programName.toUpperCase() ===
                programNameString.toUpperCase()
              );
            case 'notEquals':
              return (
                val.programName.toUpperCase() !==
                programNameString.toUpperCase()
              );
            case 'contains':
              if (
                val.programName
                  .toUpperCase()
                  .indexOf(programNameString.toUpperCase()) !== -1
              ) {
                return val.programName;
              }
              break;
            case 'notContains':
              if (
                val.programName
                  .toUpperCase()
                  .indexOf(programNameString.toUpperCase()) === -1
              ) {
                return val.programName;
              }
              break;
          }
        });
      }
      // 放送局カラムフィルター
      if (stationString) {
        basefilterData.value = basefilterData.value.filter(val => {
          switch (stationMatch) {
            case 'equals':
              return val.station.toUpperCase() === stationString.toUpperCase();
            case 'notEquals':
              return val.station.toUpperCase() !== stationString.toUpperCase();
            case 'contains':
              if (
                val.station
                  .toUpperCase()
                  .indexOf(stationString.toUpperCase()) !== -1
              ) {
                return val.station;
              }
              break;
            case 'notContains':
              if (
                val.station
                  .toUpperCase()
                  .indexOf(stationString.toUpperCase()) === -1
              ) {
                return val.station;
              }
              break;
          }
        });
      }

      // 視聴率カラムフィルター
      Object.keys(filters)
        .filter(key => !filterNameList.includes(key))
        .map(key => [
          filters[key].constraints[0].value,
          filters[key].constraints[0].matchMode,
          key
        ])
        .forEach(filterDefile => {
          const filterValue = filterDefile[0];
          const filterMatchMode = filterDefile[1];
          const filterKey = filterDefile[2];
          if (filterValue) {
            basefilterData.value = basefilterData.value.filter(val => {
              switch (filterMatchMode) {
                case 'gte':
                  return val[filterKey] >= filterValue;
                case 'lte':
                  return val[filterKey] <= filterValue;
                case 'gt':
                  return val[filterKey] > filterValue;
                case 'lt':
                  return val[filterKey] < filterValue;
              }
            });
          }
        });

      // ソート更新
      if (sortField) {
        if (
          sortField === 'date' ||
          sortField === 'dayOfWeek' ||
          sortField === 'startTime' ||
          sortField === 'station' ||
          sortField === 'programName' ||
          sortField === 'cmType' ||
          sortField === 'cmSponsor' ||
          sortField === 'cmProductSummary' ||
          sortField === 'cmCategoryLevel1Category' ||
          sortField === 'cmCategoryLevel2Category' ||
          sortField === 'cmCategoryLevel3Category' ||
          sortField === 'cmSituation'
        ) {
          if (sortOrder === 1) {
            basefilterData.value.sort((a, b) => {
              return a[sortField].toString().localeCompare(b[sortField]);
            });
          } else {
            basefilterData.value.sort((a, b) => {
              return b[sortField].toString().localeCompare(a[sortField]);
            });
          }
        } else {
          if (sortOrder === 1) {
            basefilterData.value.sort((a, b) => {
              return a[sortField] - b[sortField];
            });
          } else {
            basefilterData.value.sort((a, b) => {
              return b[sortField] - a[sortField];
            });
          }
        }
      }

      // values抽出
      const extractValue = basefilterData.value.map(val => {
        val.cmProductAfterSummary = val.cmProductSummary;
        val.isHoliday = val.isHoliday ? '祝' : '';
        delete val.cmProductSummary;
        delete val.dayOfWeekNum;
        // カスタムターゲット名が数値の時にカラム順が変わるためソートする
        /* eslint-disable @typescript-eslint/no-explicit-any */
        const concatArray: any[] = [];
        const targetArray: any[] = [];
        const otherArray: any[] = [];

        csvDataList.forEach(key => {
          if (cmTargetKey.value.includes(key)) {
            targetArray.push(val[key]);
          } else {
            otherArray.push(val[key]);
          }
        });
        concatArray.push(...otherArray, ...targetArray);
        return concatArray;
      });

      // データ結合
      const jsonCsv = csvData.value.concat(extractValue);

      csvParseResults.value = $papa.unparse({
        data: [...jsonCsv]
      });

      const link = document.createElement('a');
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, csvParseResults.value], {
        type: 'text/csv'
      });

      link.setAttribute('download', `CMlist_${convertCsvFileDate.value}`);
      link.setAttribute('href', window.webkitURL.createObjectURL(blob));
      link.click();
    } catch (e) {
      console.error(e);
      toast({ title: '失敗', message: e.message, variant: 'error' });
    } finally {
      isCsvLoading.value = false;
    }
  };

  // init
  const created = async () => {
    try {
      // 基本設定の取得
      await fetchCompanySettings();
      // エリア取得
      areaValue.value = await areaOptionStore.fetch(route);
      await onChangeArea(undefined);

      // 権限: トータルオプション
      const userInfoStore = useUserInfoStore();
      const currentWorkspace = userInfoStore.currentWorkspaceFromRoute(route);
      const featureOptionViewingTypeTotal = currentWorkspace?.featureOptions.find(
        v => v.type === WorkspaceFeatureOptionTypeEnum.ViewingTypeTotal
      );
      // FeatureOptionが存在しないか、statusがENABLEDの場合はtrueそれ以外はfalse
      isTotalOptions.value =
        featureOptionViewingTypeTotal?.status === 'ENABLED';
      viewingTypeValue.value = isTotalOptions.value
        ? ((companySetting.value
            .viewingType as unknown) as TvDataSearchCmListHistoryViewingTypeEnum)
        : TvDataSearchCmListHistoryViewingTypeEnum.Realtime;
      // areaValue, viewingTypeValueを取得してから期間初期化
      initDuration();

      // カスタムターゲット
      const getcustomTargets = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(
        companyId.value
      );
      customTargets.value = getcustomTargets.data.list;

      // 商品カテゴリ
      const getCmCategories = await CmCategoryApi.getCmCategories();
      cmCategoryData.value = getCmCategories.data;
    } catch (e) {
      console.error(e);
    }
  };
  created();

  return {
    dt,
    textMatchModes,
    numberFilterMatchModes,
    filters,
    breadcrumbs,
    isSubMenuOpen,
    isDataLoading,
    isCsvLoading,
    isDisabled,
    isOpenTable,
    isOpenModal,
    isInvalidDate,
    isZero,
    startTimeValue,
    endTimeValue,
    areaValue,
    convertArea,
    cmSponsorsValue,
    cmProductsValue,
    cmCategoryValue,
    cmCategoryData,
    cmTypeValue,
    cmTimeValue,
    isConversion15secValue,
    isConversion15secDisabled,
    tvStations,
    stationsValue,
    stationsValid,
    tvProgramsValue,
    //
    viewingTypeValue,
    cmLists,
    convertCreateReport,
    cmTargetKey,
    cmTargetWidth,
    cmSampleSize,
    targetName,
    initialTargetId,
    targetValid,
    durationRef,
    calendarId,
    minDate,
    maxDate,
    isLoadingDuration,
    convertDataTableDate,
    durationValid,
    selectHour,
    convertTimeValid,
    isLoading,
    selectOptions,
    customTargetList,
    isLoadingOnChangeArea,
    isFetchingHistory,
    isDeletingHistory,
    historyData,
    historyKey,
    fetchCmSponsors,
    fetchProducts,
    fetchPrograms,
    fetchHistory,
    deleteHistory,
    openHistoryModal,
    setHistory,
    convertViewingRateData,
    onChangeArea,
    onChangeTarget,
    onClickShowDataTable,
    onClickExportCSV,
    isTotalOptions
  };
};
