<template>
  <div class="paidPr-inner">
    <Typography line-height="1.4">
      ご利用希望の方は
      <span class="link" @click="openChatBot">こちら</span>から<br />
      お申し込みください。<br />
      弊社担当者からご連絡いたします。
    </Typography>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'paidPrInner',
  components: {
    Typography
  },
  setup() {
    // チャットボットの起動
    const openChatBot = () => {
      window.ChannelIO('showMessenger');
    };
    return {
      openChatBot
    };
  }
});
</script>
<style lang="scss" scoped>
.paidPr {
  &-inner {
    .link {
      text-decoration: underline;
      color: #4674d7;
      cursor: pointer;
    }
  }
}
</style>
