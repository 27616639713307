<template>
  <component
    :is="tag"
    v-bind="$attrs"
    ref="elementTarget"
    class="paidPr-target"
    @mouseover="over"
    @mouseleave="delayLeave"
  >
    <slot />
  </component>
  <teleport to="body">
    <div
      v-if="isHover"
      ref="tooltipTarget"
      class="paidPr"
      :style="{
        top: offsetTop + 'px',
        marginLeft: offsetLeft > 0 ? offsetLeft + 'px' : '',
        width: '252px'
      }"
      :class="[direction, $attrs.class, tooltipLeftClass]"
      @mouseover="clearTimer"
      @mouseleave="leave"
    >
      <PaidPrInner v-if="!$slots.original && !isPaidWorkspace" />
      <RestrictedPrInner v-if="!$slots.original && isRestricted" />
      <slot name="original" />
    </div>
  </teleport>
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue';
import PaidPrInner from '@/components/ui/PaidPrInner.vue';
import RestrictedPrInner from '@/components/ui/RestrictedPrInner';

export default defineComponent({
  name: 'paidPr',
  components: {
    PaidPrInner,
    RestrictedPrInner
  },
  props: {
    direction: {
      type: String,
      default: 'top'
    },
    isPaidWorkspace: {
      type: Boolean,
      default: false
    },
    isRestricted: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'span'
    },
    tooltipLeft: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const elementTarget = ref();
    const tooltipTarget = ref();
    const isHover = ref(false);
    const offsetTop = ref();
    const offsetLeft = ref();
    const timer = ref();
    const over = () => {
      isHover.value = true;
    };
    watch(
      () => tooltipTarget.value,
      tooltip => {
        if (tooltip) {
          const Rect = elementTarget.value.getBoundingClientRect();
          // 表示位置(top)
          offsetTop.value = Rect.top - (tooltip.clientHeight + 10);
          // 表示位置(left)
          offsetLeft.value = props.tooltipLeft
            ? Rect.left - tooltip.clientWidth + 70
            : Rect.left +
              elementTarget.value.clientWidth / 2 -
              tooltip.clientWidth / 2;
        }
      }
    );

    const clearTimer = () => {
      clearTimeout(timer.value);
    };
    const leave = () => {
      isHover.value = false;
    };
    const delayLeave = () => {
      timer.value = setTimeout(leave, 100);
    };
    const tooltipLeftClass = computed(() =>
      props.tooltipLeft ? 'tooltipLeft' : ''
    );
    return {
      elementTarget,
      tooltipTarget,
      isHover,
      over,
      leave,
      delayLeave,
      clearTimer,
      offsetTop,
      offsetLeft,
      tooltipLeftClass
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/layout';
.paidPr {
  position: fixed;
  border: 1px solid #4674d7;
  border-radius: 4px;
  background-color: white;
  padding: 13px;
  z-index: $z-index-header + 2;

  &::before {
    content: '';
    border-width: 10px;
    border-style: solid;
    border-color: transparent;
    position: absolute;
  }

  &-target {
    // cursor: pointer;
  }

  &.top {
    &::before {
      border-top-color: #4674d7;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.bottom {
    &::before {
      border-bottom-color: #4674d7;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.left {
    &::before {
      border-left-color: #4674d7;
      top: 50%;
      margin-top: -10px;
      right: -30px;
      transform: translateX(-50%);
    }
  }

  &.right {
    &::before {
      border-right-color: #4674d7;
      top: 50%;
      margin-top: -10px;
      left: -10px;
      transform: translateX(-50%);
    }
  }

  &.tooltipLeft {
    &::before {
      border-top-color: #4674d7;
      top: 100%;
      left: calc(100% - 65px);
      transform: translateX(-50%);
    }
  }
}
</style>
